
// Vue reactivity
import { ref, defineComponent, computed, onMounted, reactive, watch, } from 'vue';

// icons
import { add, close, checkmark, arrowUp,  arrowForward, arrowBack, checkbox, trashOutline, } from 'ionicons/icons';

// components
import { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter, IonRow, IonCol, IonAccordion, IonAccordionGroup,
        IonItem, IonLabel, IonIcon, IonButtons, IonButton, IonReorderGroup, IonReorder,
        IonSearchbar, IonSegment, IonSegmentButton, IonList, IonSelect, IonSelectOption, IonCheckbox,
        IonCard, IonCardHeader, IonCardSubtitle, IonCardContent, IonChip, IonText, IonCardTitle, IonGrid,
        IonTextarea, } from '@ionic/vue';
import SectorModal from '@/components/pss/SectorModal.vue';

// composables
import { useI18n } from 'vue-i18n';
import { utils } from '@/composables/utils';
import { useStore } from '@/store';

// services
import PortalService from '@/services/PortalService';

// types
import { Segment } from '@/types';

export default defineComponent({
  name: 'EmployerSelectModal',
  props: ["prefilledEmployers", "prefilledClaim1", "prefilledClaim2", "prefilledClaim3",
          "prefilledSegments", "segmentOptions"],
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter, IonRow, IonCol, IonAccordion, IonAccordionGroup,
                IonItem, IonLabel, IonIcon, IonButtons, IonButton, IonReorderGroup, IonReorder,
                IonSearchbar, IonSegment, IonSegmentButton, IonList, IonSelect, IonSelectOption, IonCheckbox,
                IonCard, IonCardHeader, IonCardSubtitle, IonCardContent, IonChip, IonText, IonCardTitle, IonGrid,
                IonTextarea, },
  setup(props) {
    // methods or filters
    const store = useStore();
    const { closeModal, doReorder, openModal, } = utils();
    const { t } = useI18n();

    const selectedMode = ref(1);
    const searchKeyword = ref("");
    const isSearching = ref(false);
    
    const employerOptions = ref([]);
    const chosenSegments = ref<Segment[]>(props.prefilledSegments || []);
    const chosenEmployers = ref<any[]>(props.prefilledEmployers || []);
    const data = reactive({
      claim1: props.prefilledClaim1 || "",
      claim2: props.prefilledClaim2 || "",
      claim3: props.prefilledClaim3 || "",
    })

    const confirmSelect = async () => {
      await closeModal({ selectedEmployers: chosenEmployers.value, ...data }); // return selected discipline & order here
    };

    const refreshRelatedEmployers = async (targetSegments: Segment[]) => {
      let relatedEmployers: any = [];
      if (targetSegments.length > 0) {
        const res =  await PortalService.getEmployersBySegmentIds(targetSegments.map((s: any) => s.id));
        for (const employerArr of Object.values(res['local'])) {
          relatedEmployers = relatedEmployers.concat(employerArr);
        }
        for (const employerArr of Object.values(res['mnc'])) {
          relatedEmployers = relatedEmployers.concat(employerArr);
        }
      }
      // filter out selected employers that are not in the new list
      const relatedEmployerIds = relatedEmployers.map((e: any) => e.id);
      chosenEmployers.value = chosenEmployers.value.filter((e: any) => relatedEmployerIds.includes(e.id));
      employerOptions.value = relatedEmployers;
    }

    watch(selectedMode, (currMode) => {
      if (currMode == 2) {
        refreshRelatedEmployers(chosenSegments.value);
      }
    })

    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      add, close, checkmark, arrowUp, arrowForward, arrowBack, checkbox, trashOutline,

      // variables
      chosenSegments, chosenEmployers, employerOptions,
      selectedMode, searchKeyword, isSearching,
      data,

      // methods
      t, confirmSelect,
      closeModal,
      doReorder,

      onClickNext: () => {
        if (selectedMode.value == 1) {
          refreshRelatedEmployers(chosenSegments.value);
        }
        selectedMode.value++;
      },

      isNextDisabled: () => {
        if (selectedMode.value == 1) return chosenSegments.value.length == 0;
        return false;
      },

      // Segments
      openSegmentModal: async (segment: any) => (await openModal(SectorModal, { sectorId: segment.sectorId, segmentId: segment.id })),
      onCheckSegment: (checked: any, chosen: any) => {
        if (checked) {
          if (chosenSegments.value.find(d => d.id == chosen.id) == null) {
            chosenSegments.value.push(chosen);
          }
        }
        else {
          const idx = chosenSegments.value.findIndex(d => d.id == chosen.id);
          if (idx !== -1) chosenSegments.value.splice(idx, 1);
        }
      },

      // Employers
      onCheckEmployer: (checked: any, chosen: any) => {
        if (checked) {
          if (chosenEmployers.value.find(d => d.id == chosen.id) == null) {
            chosenEmployers.value.push(chosen);
          }
        }
        else {
          const idx = chosenEmployers.value.findIndex(d => d.id == chosen.id);
          if (idx !== -1) chosenEmployers.value.splice(idx, 1);
        }
      },
    }
  }
});

import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b47eed98"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "ion-text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_accordion = _resolveComponent("ion-accordion")!
  const _component_ion_accordion_group = _resolveComponent("ion-accordion-group")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_header, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_toolbar, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_buttons, { slot: "start" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_button, {
                  slot: "icon-only",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeModal()))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, { icon: _ctx.close }, null, 8, ["icon"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_ion_title, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                  default: _withCtx(() => [
                    _createElementVNode("p", null, "CV examples for " + _toDisplayString(_ctx.chosenProfession.name), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_content, { color: "light" }, {
      default: _withCtx(() => [
        (_ctx.loadingExamples)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(_component_ion_spinner)
            ]))
          : (_openBlock(), _createBlock(_component_ion_accordion_group, {
              key: 1,
              multiple: true
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cvBestExamples, (example) => {
                  return (_openBlock(), _createBlock(_component_ion_accordion, {
                    key: example.headline,
                    value: example.headline
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_item, {
                        color: "medium",
                        slot: "header"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                            default: _withCtx(() => [
                              _createElementVNode("b", null, _toDisplayString(example.headline), 1)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1024),
                      _createVNode(_component_ion_list, {
                        class: "suggestion-list",
                        slot: "content"
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(example.bulletPoints, (point, i) => {
                            return (_openBlock(), _createBlock(_component_ion_item, {
                              lines: "full",
                              key: i
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("p", null, _toDisplayString(point), 1)
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_ion_button, {
                                  fill: "outline",
                                  slot: "end",
                                  size: "small",
                                  onClick: ($event: any) => (_ctx.copyText(point)),
                                  style: {"margin-inline-start":"16px"}
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(" Copy ")
                                  ]),
                                  _: 2
                                }, 1032, ["onClick"])
                              ]),
                              _: 2
                            }, 1024))
                          }), 128))
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1032, ["value"]))
                }), 128))
              ]),
              _: 1
            }))
      ]),
      _: 1
    })
  ], 64))
}

// Vue reactivity
import { ref, defineComponent, computed, watch, reactive, onMounted } from 'vue';

// icons
import { add, close, checkmark, arrowUp,  arrowForward, arrowBack, trashOutline, } from 'ionicons/icons';

// components
import { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter, IonRow, IonCol, IonAccordion, IonAccordionGroup,
        IonItem, IonLabel, IonIcon, IonButtons, IonButton, IonInput,
        IonSearchbar, IonSegment, IonSegmentButton, IonList, IonSelect, IonSelectOption,
        IonCard, IonCardHeader, IonCardSubtitle, IonCardContent, IonChip, IonText, IonCardTitle, IonGrid, IonCheckbox,
        loadingController, modalController, } from '@ionic/vue';
import ABEventsDetails from "@/components/secondary/events/ABEventsDetails.vue";

// composables / services
import { useI18n } from 'vue-i18n';
import { utils } from '@/composables/utils';
import { useStore } from '@/store';

// types
import { User, Session, Lesson, } from '@/types';

export default defineComponent({
  name: 'ABEventsModal',
  props: ["targetAnchorEvId"],
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter, IonRow, IonCol, IonAccordion, IonAccordionGroup,
                IonItem, IonLabel, IonIcon, IonButtons, IonButton, IonChip, IonText, IonGrid, IonCheckbox,
                IonSearchbar, IonSegment, IonSegmentButton, IonList, IonSelect, IonSelectOption,
                IonCard, IonCardHeader, IonCardSubtitle, IonCardContent, IonCardTitle, IonInput,
                ABEventsDetails, },
  setup(props) {
    // methods or filters
    const store = useStore();
    const { openModal, presentPrompt, getProxyImgLink, openImageModal, presentToast, } = utils();
    const { t } = useI18n();

    //const selectedABSEventFilter = ref("all");
    const user = computed(() => store.state.user);
    const lesson = computed<Lesson>(() => store.getters.getLessonByAnchorEventId(props.targetAnchorEvId));
    const userABSIntakeSessions = computed<Session[]>(() => store.getters.userABSIntakeSessions);
    const filteredSessions = ref<Session[]>([]);
    const isAB4User = computed(() => store.getters.isAB4User);

    const closeModal = async () => {
      await modalController.dismiss({});
    }

    onMounted(() => {
      filteredSessions.value = userABSIntakeSessions.value.filter(s => s.anchorEventId == props.targetAnchorEvId).map(s => ({ ...s }));
    })

    // return variables & methods to be used in template HTML
    return {
      // icons
      add, close, checkmark, arrowUp, arrowForward, arrowBack,
      trashOutline,

      // variables
      user,
      isAB4User,
      filteredSessions,
      lesson,

      // methods
      t,
      closeModal,
      getProxyImgLink,
      openImageModal,
      isShowEvent: (ev: Session) => {
        //return true;
        return (new Date(ev.startTimeStr) > new Date(+new Date() - 2*86400000) || ev.userResponse?.confirmed == "Yes"); // show events (up to 2 days past)
      },
      getSeatingPlanImgLink: () => {
        const { targetAnchorEvId: id } = props;
        const slideId = id == 'abs-session1' ? 'g24fff357604_0_102' : 'g24fff357604_0_146';
        return getProxyImgLink(`https://docs.google.com/presentation/d/1B5qHJu7ZV72CUOMD4VSoJ2Ptg82prAM0t1hnzxmrp0c/export/jpeg?id=1B5qHJu7ZV72CUOMD4VSoJ2Ptg82prAM0t1hnzxmrp0c&pageid=${slideId}`);
      },
      isPastEvent: (ev: any) => {
        return ev.userResponse?.attended != 'Yes' && new Date().getTime() > new Date(ev.endTimeStr).getTime()+60*60000;
      },
    }
  },
});

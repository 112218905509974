
// Vue reactivity
import { ref, defineComponent, computed, watch, reactive } from 'vue';

// icons
import { add, close, arrowBack, checkmarkCircle, alertCircle, ellipsisHorizontal, ellipsisVertical,
        pencil, qrCodeOutline, } from 'ionicons/icons';

// components
import { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter, IonRow, IonCol,
        IonItem, IonLabel, IonIcon, IonButtons, IonButton, IonSpinner, IonList,
        IonCard, IonCardHeader, IonCardSubtitle, IonCardContent, IonPopover,
        IonAccordion, IonAccordionGroup, IonText, IonChip, IonProgressBar, IonSegment, IonSegmentButton,
        loadingController, modalController, alertController, } from '@ionic/vue';
import SchoolTeacherModal from '@/components/teacher/SchoolTeacherModal.vue';
import StudentModal from '@/components/teacher/SchoolStudentModal.vue';
import ServiceModal from '@/components/modals/ServiceModal.vue';
import ABEventsModal from '@/components/secondary/events/ABEventsModal.vue';
import AB4CustomizeContentModal from '@/components/teacher/AB4CustomizeContentModal.vue';
import TeacherResponseQuestions from '@/components/teacher/TeacherResponseQuestions.vue';
import TeacherEventsModal from '@/components/teacher/TeacherEventsModal.vue';
import TeacherAchieveJUPASModal from '@/components/achievejupas/TeacherAchieveJUPASModal.vue';
import ServiceItem from '@/components/shared/ServiceItem.vue';
import ServiceAccordionGroup from '@/components/shared/ServiceAccordionGroup.vue';
import ActionAccordionGroup from '@/components/shared/ActionAccordionGroup.vue';
import TeacherItemQRCodeSharing from '@/components/teacher/TeacherItemQRCodeSharing.vue';
import TeacherItemSchoolReadiness from '@/components/teacher/TeacherItemSchoolReadiness.vue';
import FormQuestionModal from '@/components/modals/FormQuestionModal.vue';
import SLPModal from '@/components/secondary/ABSLPModal.vue';

// composables
import { useI18n } from 'vue-i18n';
import { utils } from '@/composables/utils';
import { useStore } from '@/store';
import { AnchorEventTask, Service, Session, TeacherResponse, User } from '@/types';
import config from '@/config';

export default {
  props: ["prefilledViewType"],
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter, IonRow, IonCol,
    IonItem, IonLabel, IonIcon, IonButtons, IonButton, IonSpinner, IonList,
    IonCard, IonCardHeader, IonCardSubtitle, IonCardContent, IonPopover,
    IonAccordion, IonAccordionGroup, IonText, IonChip, IonProgressBar, IonSegment, IonSegmentButton,
    TeacherResponseQuestions, TeacherEventsModal, TeacherAchieveJUPASModal,
    ServiceAccordionGroup, ServiceItem, ActionAccordionGroup, TeacherItemQRCodeSharing, TeacherItemSchoolReadiness, },
  setup(props) {
    // methods or filters
    const store = useStore();
    const { t } = useI18n();
    const { openModal, sleep, presentToast, presentPrompt, getQRCodeUrl, sendLinkToMyWhatsApp, formatDate,
            getIntakeYearOfDate, getCurrentIntakeYear, openImageModal, getProxyImgLink, copyText, } = utils();

    //const loadingData = computed(() => store.state.loadingUser || store.state.loadingData || store.state.loadingSchoolUserData);
    const loadingData = computed(() => store.state.loadingUser || store.state.loadingData);
    const user = computed(() => store.state.user);
    const userRelatedSchool = computed(() => store.getters.userRelatedSchool);
    const isAB4User = computed(() => store.getters.isAB4User);
    const hasTeacherEvents = computed<Session[]>(() => store.getters.getSessionsByAnchorEventId('c0bcf00d0')).value.length > 0;

    // TMP: for 14 Mar 2025 event
    const anchorEventTasks = computed<AnchorEventTask[]>(() => store.state.anchorEventTasks);
    const loadingUserFormResponses = computed(() => store.state.loadingUserFormResponses);
    const userFormResponses = computed(() => store.state.userFormResponses);
    const getFilteredFormQuestions = (task: AnchorEventTask) => (task.formQuestions.filter(q => (q.workServiceIds.length == 0 || q.workServiceIds.includes(props.ev.serviceId))));
    const getTaskResponses = (tid: string) => (userFormResponses.value.filter(r => r.taskId == tid));
    const isSubmittedTaskForm = (task: AnchorEventTask) => (getTaskResponses(task.id).length >= getFilteredFormQuestions(task).length);
  
    // Toggle views
    const targetForm = ref("F5-6");
    const selectedViews = reactive({
      type: props.prefilledViewType || "AchieveJUPAS",
      subType: "resources",
    }); // mainly for switching between AchieveJUPAS & Resources

    // Filter service by school roles (or customized wordings)
    const allSchoolRoles = computed(() => store.state.schoolRoles);
    const userSchoolRoles = () => ((user.value.teacher?.schoolRoles || "").split(" , ").filter(r => r));
    const selectedSchoolRole = ref(userSchoolRoles()[0] || ""); // XXX-related career prospect

    // Services & actions
    const services = computed<Service[]>(() => store.state.services);
    const getFilteredServices = (targetPrincipalOnly = false, allowProposeDatesOnly: any = null) => {
      return services.value.filter(s => {
        if (s.type == "jobEX") return false;
        if (s.id == 'ucircle') return true; // special case: nominate students for UCircle
        if (allowProposeDatesOnly !== null && s.isAllowProposeDates != allowProposeDatesOnly) return false;
        if (s.isForPrincipalOnly != targetPrincipalOnly) return false;
        if (s.status == 'Inactive' || s.status == 'Removed') return false;
        return s.targetSchoolBands.length == 0 || userRelatedSchool.value?.id == 'beacon1' || s.targetSchoolBands.includes(userRelatedSchool.value?.band)
      });
    }

    watch(targetForm, (curr) => {
      switch (curr) {
        case 'F4':
          selectedViews.type = 'AB4';
          break;
        default:
          selectedViews.type = 'AchieveJUPAS';
          break;
      }
    })

    // return variables & methods to be used in template HTML
    return {
      // icons
      add, close, arrowBack,
      checkmarkCircle, alertCircle, ellipsisHorizontal, ellipsisVertical, pencil, qrCodeOutline,

      // variables
      user, isAB4User,
      loadingData,
      services,
      hasTeacherEvents,
      isPrincipal: computed(() => (user.value.teacher && userSchoolRoles().some(r => ["Principal", "Vice Principal"].includes(r)))),
      userSchoolRoles, selectedSchoolRole,
      filteredSchoolRoles: () => (config.formOptions.rolesInSchool.filter(r => !userSchoolRoles().includes(r))),
      getSchoolRoleAlias: (role: string) => (allSchoolRoles.value.find(r => r.title == role)?.alias || role),

      // TMP: 14 Mar events
      anchorEventTasks, loadingUserFormResponses, userFormResponses,
      openFormQuestionModal: async (task: AnchorEventTask) => {
        const { id: taskId, title, } = task;
        const formTitle = `${title}`; // modal title
        const oldResponses = getTaskResponses(taskId);
        const formQuestionIds = getFilteredFormQuestions(task).map(q => q.id);
        return await openModal(FormQuestionModal, { formTitle, formQuestionIds, taskId, oldResponses });
      },
      isSubmittedTaskForm, getTaskResponses, getFilteredFormQuestions,
      achievejupasSurveyTask: computed(() => anchorEventTasks.value.find(t => t.id == 't7ef068da') as AnchorEventTask),
      achieveslpSurveyTask: computed(() => anchorEventTasks.value.find(t => t.id == 't47822ace') as AnchorEventTask),

      // methods
      t,
      formatDate,
      closeModal: async () => (await modalController.dismiss({})),
      openStudentModal: async (initialSection = 2) => (await openModal(StudentModal, { schoolId: user.value.schoolId, initialSection })),
      sendLinkToMyWhatsApp,
      getQRCodeUrl,

      openImageModal, getProxyImgLink, copyText,
      openServiceModal: async (serviceId: any, event: any = null) => {
        if (serviceId) {
          return await openModal(ServiceModal, { serviceId, event })
        }
      },
      openABEventsModal: async (targetAnchorEvId) => (await openModal(ABEventsModal, { targetAnchorEvId })),
      openAB4CustomizeContentModal: async () => (await openModal(AB4CustomizeContentModal, { })),
      openTeacherEventsModal: async (anchorEvId) => (await openModal(TeacherEventsModal, { anchorEvId })),
      openSchoolTeacherModal: async () => (await openModal(SchoolTeacherModal, {})),
      openTeacherAchieveJUPASModal: async () => (await openModal(TeacherAchieveJUPASModal, {})),

      // popover
      setPopoverOpen: (service: any, state: boolean, ev = null) => {
        service.popoverEvent = ev;
        service.isPopoverOpened = state;
      },

      // Filter service list based on teacher school banding
      getFilteredServices,
      groupedServices: (targetPrincipalOnly = false, allowProposeDatesOnly: any = null, targetForm: any = null, returnArr = false, scheduledServiceOnly = false) => {
        const obj = {}, filteredServices: any = [];
        const targetServices = getFilteredServices(targetPrincipalOnly, allowProposeDatesOnly);
        for (const service of targetServices) {
          if (targetForm && service.targetForms && service.targetForms.length > 0 && !service.targetForms.includes(targetForm)) {
            continue; // only include services related to the form
          }
          let group = service.nature || 'Others';
          //if (service.id == 'mock-jupas') group = "FDMT unique";
          if (service.id == 'mock-jupas') group = "2. AchieveJUPAS";
          else if (group == 'Talk at your school') group = "3. University lecture at your school";
          //else if (group == 'Talk at your school') group = "[Subject enrichment] 30-min University lecture at your school";
          else if (group == "Workshop") group = "[Post-exam period] 4-hr university workshop";
          //else if (group == "Visit") group = "[Annual outing] 2-hr university visit";
          else if (group == "Visit") group = "1. Work visits";
          else if (group == "20-min Online Briefing") group = "[Class teacher session] 15-min Zoom reminders";
          //if (group == "Workshop") group = "Nominate students to Workshops on university campus";
          //else if (group == "Visit") group = "Lead students to University visits at schools’ preferred time";
          obj[group] = obj[group] || [];
          obj[group].push(service);
          filteredServices.push(service);
        }
        return returnArr ? filteredServices : obj;
      },

      // For view switching between forms & type
      selectedViews, targetForm,

      getServicesByNature: (nature) => {
        const filteredServices = getFilteredServices();
        if (nature == 'Video') return filteredServices.filter(s => s.name.toLowerCase().includes(" preview"));
        return filteredServices.filter(s => s.nature === nature && !s.name.toLowerCase().includes(" preview"));
      },

      // AchieveSLP
      openSLPModal: async (isPreview = false) => {
        const ev = store.getters.getLastAttendedSession;
        return await openModal(SLPModal, { isPreview, ev, serviceId: ev?.serviceId, relatedProgramId: ev?.relatedProgramId, relatedClientId: user.value.clientId }, "", false)
      },
    }
  },
};

import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1275c60e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_accordion = _resolveComponent("ion-accordion")!
  const _component_ion_accordion_group = _resolveComponent("ion-accordion-group")!

  return ($props.noAccordion)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (!$props.noHeader)
          ? (_openBlock(), _createBlock(_component_ion_item, {
              key: 0,
              lines: "none",
              color: $props.headerColor || 'fdmtred'
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_label, {
                  class: _normalizeClass({'ion-padding-start': !$props.noPadding})
                }, {
                  default: _withCtx(() => [
                    _renderSlot(_ctx.$slots, "header", {}, undefined, true)
                  ]),
                  _: 3
                }, 8, ["class"])
              ]),
              _: 3
            }, 8, ["color"]))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: _normalizeClass({'ion-padding-start': !$props.noPadding})
        }, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ], 2)
      ]))
    : (_openBlock(), _createBlock(_component_ion_accordion_group, {
        key: 1,
        value: $props.value
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_accordion, {
            style: {"--min-height":"24px"},
            value: $props.value
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item, {
                lines: "none",
                slot: "header",
                color: $props.headerColor || 'fdmtred'
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, {
                    class: _normalizeClass({'ion-padding-start': !$props.noPadding})
                  }, {
                    default: _withCtx(() => [
                      _renderSlot(_ctx.$slots, "header", {}, undefined, true)
                    ]),
                    _: 3
                  }, 8, ["class"])
                ]),
                _: 3
              }, 8, ["color"]),
              _createElementVNode("div", {
                class: _normalizeClass({'ion-padding-start': !$props.noPadding}),
                slot: "content"
              }, [
                _renderSlot(_ctx.$slots, "default", {}, undefined, true)
              ], 2)
            ]),
            _: 3
          }, 8, ["value"])
        ]),
        _: 3
      }, 8, ["value"]))
}
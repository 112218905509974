
// Vue reactivity
import { ref, defineComponent, computed, watch, reactive, onMounted, onUnmounted, onBeforeUnmount } from 'vue';

// icons
import { add, close, checkmark, arrowUp, arrowForward, arrowBack, trashOutline,
        thumbsUpOutline, thumbsDownOutline, thumbsUp, thumbsDown, heart, heartOutline,
        chevronBack, chevronForward, repeat, search, pencil, refresh, } from 'ionicons/icons';

// components
import { IonHeader, IonGrid, IonToolbar, IonTitle, IonContent, IonFooter, IonRow, IonCol, IonTextarea, IonText,
        IonItem, IonLabel, IonIcon, IonButtons, IonButton, IonReorderGroup, IonReorder, IonThumbnail,
        modalController } from '@ionic/vue';
import ProfessionModal from '@/components/pss/ProfessionModal.vue';
import ABProfessionSelectModal from '@/components/pss/profession/ABProfessionSelectModal.vue';

// composables
import { useI18n } from 'vue-i18n';
import { utils } from '@/composables/utils';
import { useStore } from '@/store';

// types
import { Profession, UserProfession } from '@/types';

// services
import ABSService from '@/services/ABSService';

export default defineComponent({
  name: 'AB4ProfessionResultPageModal',
  props: ["prefilledProfessions", "oldUserProfessions"],
  components: { IonHeader, IonGrid, IonToolbar, IonTitle, IonContent, IonFooter, IonRow, IonCol, IonTextarea, IonText,
                IonItem, IonLabel, IonIcon, IonButtons, IonButton, IonReorderGroup, IonReorder, IonThumbnail, },
  setup(props) {
    // methods or filters
    const { t } = useI18n();
    const store = useStore();
    const { closeModal, doReorder, openModal, processUserItems, syncChosenItems, } = utils();

    const user = computed(() => store.state.user);
    const settings = computed(() => store.state.settings);

    const selectedProfessions = ref<Profession[]>(props.prefilledProfessions || []);
    const userProfessions = ref<UserProfession[]>(props.oldUserProfessions || []);
    const allProfessions = ref<Profession[]>(store.getters.shuffledProfessions(false, true));

    const confirmSelect = async (noLoading = false) => {
      await closeModal({
        "selectedProfessions": selectedProfessions.value,
        "userProfessions": processUserItems(selectedProfessions.value, userProfessions.value, [], 'professionId', user.value.id),
        noLoading,
      }); // return selected profession & order here
    };
    const openProfessionModal = async (professionId: any) => await openModal(ProfessionModal, { professionId, useBackButton: true });

    // Record Access & Leave Time
    let accessTime, duration = 0, counterInterval;
    onMounted(() => {
      accessTime = new Date();
      counterInterval = setInterval(() => (duration++), 1000);

      // Prefill with previously written reasons
      syncChosenItems('professionId', selectedProfessions, userProfessions, allProfessions.value);
    })
    onBeforeUnmount(() => {
      if (accessTime && duration >= 5) {
        ABSService.insertPageAccessRecord('AB4ProfessionResultPageModal', accessTime, new Date(accessTime.getTime() + duration*1000));
        accessTime = undefined;
        duration = 0; // reset;
        clearInterval(counterInterval);
      }
    })

    // For auto save
    const saveUserProfession = () => {
      const updatedUserProfessions = processUserItems(selectedProfessions.value, userProfessions.value, [], 'professionId', store.state.user.id);
      ABSService.upsertUserProfessions(updatedUserProfessions);
    };

    // return variables & methods to be used in template HTML
    return {
      // icons
      add, close, checkmark, arrowUp, arrowForward, arrowBack, trashOutline,
      thumbsUpOutline, thumbsDownOutline, thumbsUp, thumbsDown, heart, heartOutline,
      pencil, refresh,

      // variables
      user, settings,
      selectedProfessions, userProfessions,

      // methods
      t,
      confirmSelect, closeModal, openProfessionModal,
      doReorder,

      // Auto-save
      saveUserProfession,
      doProfessionReorder: (event: CustomEvent, targetArr: any) => {
        doReorder(event, targetArr);
        saveUserProfession(); // auto save
      },

      onDeleteChosenProfession: (idx, profession) => {
        selectedProfessions.value.splice(idx, 1);
        const relatedUserItem = userProfessions.value.find(us => us.professionId == profession.id);
        if (relatedUserItem) relatedUserItem.reaction = '';
      },

      openProfessionDeckModal: async () => {
        const modal = await modalController.create({
          cssClass: 'tall-modal',
          component: ABProfessionSelectModal,
          componentProps: {
            prefilledProfessions: selectedProfessions.value.slice(),
            oldUserProfessions: userProfessions.value.slice() || [],
          }
        });
        modal.onDidDismiss().then(({ data }) => {
          if (data && data.selectedProfessions) {
            selectedProfessions.value = data.selectedProfessions;
            userProfessions.value = data.userProfessions;
          }
        });
        return modal.present();
      }
    }
  },
});

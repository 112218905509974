
// Vue reactivity
import { ref, defineComponent, computed, watch, onMounted, } from 'vue';

// icons
import { add, close, checkmark, arrowUp, arrowForward, arrowBack, trashOutline, caretDown, createOutline, openOutline, } from 'ionicons/icons';

// components
import { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter, IonRow, IonCol, IonAccordion, IonAccordionGroup,
        IonItem, IonLabel, IonIcon, IonButtons, IonButton, IonReorderGroup, IonReorder,
        IonSearchbar, IonSegment, IonSegmentButton, IonList, IonSelect, IonSelectOption,
        IonCard, IonCardHeader, IonCardSubtitle, IonCardContent, IonChip, IonText, IonCheckbox,
        IonInfiniteScroll, IonInfiniteScrollContent, IonSpinner, IonModal,
        modalController, } from '@ionic/vue';
import CVExamplesModal from "@/components/university/CVExamplesModal.vue";
import CLModal from '@/components/university/CLModal.vue';
import ChatbotModal from '@/components/modals/ChatbotModal.vue';

// composables
import { useI18n } from 'vue-i18n';
import { utils } from '@/composables/utils';
import { useStore } from '@/store';

// services
import PortalService from '@/services/PortalService';

export default defineComponent({
  name: 'CVModal',
  props: [],
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter, IonRow, IonCol, IonAccordion, IonAccordionGroup,
                IonItem, IonLabel, IonIcon, IonButtons, IonButton, IonReorderGroup, IonReorder,
                IonSearchbar, IonSegment, IonSegmentButton, IonList, IonSelect, IonSelectOption,
                IonCard, IonCardHeader, IonCardSubtitle, IonCardContent, IonChip, IonText, IonCheckbox,
                IonInfiniteScroll, IonInfiniteScrollContent, IonSpinner, IonModal, },
  setup(props) {
    // methods or filters
    const store = useStore();

    const { openModal, closeModal, presentToast, getProxyImgLink, openBookingModal, } = utils();
    const { t } = useI18n();

    const loading = computed(() => store.state.loadingData || store.state.loadingPortalData || store.state.loadingProgram);
    const loadingExamples = ref(false);
    const user = computed(() => store.state.user);
    const professionsWithCv = computed(() => store.getters.professionsWithCv);
    const searchKeyword = ref("");
    const selectedProfession = ref(null);
    const cvBestExamples = ref([]);
    const selectedBulletPoints = ref([]);

    const section = ref(1);
    const lastSection = 4;

    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      add, close, checkmark, arrowUp, arrowForward, arrowBack, trashOutline, caretDown, createOutline, openOutline,

      // variables
      loading, loadingExamples, user,
      section, lastSection,
      searchKeyword, professionsWithCv, selectedProfession,
      cvBestExamples, selectedBulletPoints,

      // methods
      t,
      closeModal, getProxyImgLink,

      openCLModal: async () => {
        await openModal(CLModal, {});
        section.value = 1;
      },
      openCVExamplesModal: async (p) => (await openModal(CVExamplesModal, { chosenProfession: p })),
      openChatbotModal: async () => (await openModal(ChatbotModal, {})),

      // for online consultation
      openBookingModal,
    }
  }
});

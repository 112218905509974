
// Vue reactivity
import { ref, defineComponent, computed, onMounted, watch, } from 'vue';

// icons
import { add, close, checkmark, arrowUp,  arrowForward, arrowBack, checkbox, trashOutline, pencil, copyOutline, } from 'ionicons/icons';

// components
import { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter, IonRow, IonCol, IonAccordion, IonAccordionGroup,
        IonItem, IonLabel, IonIcon, IonButtons, IonButton, IonReorderGroup, IonReorder, IonCheckbox, IonFab, IonFabButton,
        IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, IonNote, IonTextarea, IonInput, IonList, IonText,
        IonModal,
        isPlatform, getPlatforms, modalController, loadingController } from '@ionic/vue';

// composables
import { useI18n } from 'vue-i18n';
import { utils } from '@/composables/utils';
import { useStore } from '@/store';

// services
import JobEXService from '@/services/JobEXService';

// types
import { UserCLClaim, UserCLExp, } from '@/types';

export default defineComponent({
  name: 'CLModal',
  props: [],
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter, IonRow, IonCol, IonAccordion, IonAccordionGroup,
                IonItem, IonLabel, IonIcon, IonButtons, IonButton, IonReorderGroup, IonReorder, IonCheckbox, IonFab, IonFabButton,
                IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, IonNote, IonTextarea, IonInput, IonList, IonText,
                IonModal, },
  setup(props) {
    // methods or filters
    const store = useStore();
    const { closeModal, doReorder, openModal, uniqueId, copyText, presentPrompt, } = utils();
    const { t } = useI18n();

    const section = ref("claims");

    const sampleClaims = `I have experience in the hotel industry
I have frontline customer experience.
I studied the relevant majors
I’ve strong overseas exposure
I can deliver under pressure
I am confident to adapt to the working world fast`.split("\n").map(c => `e.g. ${c}`);

    const sampleExps = `I study [major] and graduate with a first honor
I have been using in your product for 5 years
I interned at your existing customer
I was the vice chairperson of the students' society
I won a case competition
I increased revenue by 50% for my past employers
3 generations of my family live in Yuen Long
I have kept a dog for 3 years`.split("\n").map(e => `e.g. ${e}`);

    const user = computed(() => store.state.user);
    const selectedClaims = ref<UserCLClaim[]>([]);
    const claims = ref<UserCLClaim[]>(sampleClaims.map(c => ({ id: `c${uniqueId()}`, text: "", placeholder: c })));
    const editingClaim = ref<UserCLClaim>({});

    const selectedExps = ref<UserCLExp[]>([]);
    const pastExps = ref<UserCLExp[]>(sampleExps.map(e => ({ id: `e${uniqueId()}`, text: "", placeholder: e })));

    onMounted(() => {
      if (user.value.clClaims && user.value.clClaims.length > 0) claims.value = user.value.clClaims;
      if (user.value.clExps && user.value.clExps.length > 0) pastExps.value = user.value.clExps;
    })
    
    return {
      // icons
      add, close, checkmark, arrowUp, arrowForward, arrowBack, checkbox, trashOutline, pencil,

      // variables
      section,

      // methods
      t,
      copyText,
      closeModal,
      doReorder,
      backToHomePage: async () => {
        const loading = await loadingController.create({ });
        await loading.present();
        JobEXService.upsertUserClaims(claims.value, pastExps.value);
        store.commit('upsertUserCLClaims', { userClaims: claims.value, userExps: pastExps.value })
        loading.dismiss();
        closeModal({});
      },

      // Claims
      claims, editingClaim, selectedClaims, sampleClaims,
      addNewClaim: () => {
        claims.value.push({ id: `c${uniqueId()}`, text: "" });
      },
      onEditClaim: (claim) => {
        //selectedExps.value = (claim.expIds || []).map(id => pastExps.value.find(exp => exp.id == id)).filter(exp => !!exp);
        selectedExps.value = []; // reset selected exps
        editingClaim.value = claim;
        section.value = "experiences";
      },
      onCheckClaim: (checked: any, claim: any) => {
        if (checked) {
          if (selectedClaims.value.find(c => c.id == claim.id) == null) {
            selectedClaims.value.push(claim);
          }
        }
        else {
          const idx = selectedClaims.value.findIndex(c => c.id == claim.id);
          if (idx !== -1) selectedClaims.value.splice(idx, 1);
        }
      },
      copyChosenClaims: () => {
        //const text = selectedClaims.value.map(c => `${c.text}\n${c.expIds?.map(id => (pastExps.value.find(e => e.id == id) || {}).text).join('\n')}`).join('\n\n');
        const text = selectedClaims.value.map(c => `${c.text}\n${c.expElaboration}`).join('\n\n');
        copyText(text, `Claims copied.`);
        selectedClaims.value = [];
      },
      presentConfirmDeleteClaim: (idx) => {
        presentPrompt("Confirm delete this claim?", () => {
          claims.value.splice(idx, 1)
        });
      },

      // Past Experiences
      pastExps, selectedExps, sampleExps,
      onAddPastExp: () => {
        pastExps.value.push({ id: `e${uniqueId()}`, text: "" });
      },
      onDeletePastExp: (exp, idx) => {
        const deleteExpCallback = () => {
          pastExps.value.splice(idx, 1);
          const selectedIdx = selectedExps.value.findIndex(e => e.id == exp.id);
          if (selectedIdx !== -1) selectedExps.value.splice(selectedIdx, 1);
        }
        if (exp.text) {
          presentPrompt("Confirm delete this experience?", deleteExpCallback);
        } else {
          deleteExpCallback();
        }
      },
      onCheckPastExp: (checked: any, exp: UserCLExp, idx) => {
        if (checked) {
          if (selectedExps.value.find(e => e.id == exp.id) == null) {
            if (!exp.text) exp.text = (exp.placeholder || sampleExps[idx])?.replace("e.g. ", "");
            //editingClaim.value.expElaboration = `${editingClaim.value.expElaboration || ''}\n\n${exp.text}`.trim();
            selectedExps.value.push(exp);
          }
        }
        else {
          const idx = selectedExps.value.findIndex(e => e.id == exp.id);
          if (idx !== -1) selectedExps.value.splice(idx, 1);
        }
      },
      backToClaimsPage: () => {
        const newExpsText = selectedExps.value.map(exp => exp.text).join("\n\n");
        editingClaim.value.expElaboration = `${editingClaim.value.expElaboration || ''}\n\n${newExpsText}`.trim();
        editingClaim.value.expIds = selectedExps.value.map(exp => exp.id); // link exps to the claim
        section.value = 'claims';
      },
    }
  }
});


// Vue reactivity
import { ref, defineComponent, computed, watch, reactive, onMounted } from 'vue';

// icons
import { add, close, checkmark, arrowUp,  arrowForward, arrowBack, trashOutline, } from 'ionicons/icons';

// components
import { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter, IonRow, IonCol, IonAccordion, IonAccordionGroup,
        IonItem, IonLabel, IonIcon, IonButtons, IonButton, IonSpinner,
        IonSearchbar, IonSegment, IonSegmentButton, IonList, IonSelect, IonSelectOption,
        IonCard, IonCardHeader, IonCardSubtitle, IonCardContent, IonChip, IonText, IonCardTitle, IonGrid, IonCheckbox,
        loadingController, modalController, } from '@ionic/vue';

// composables / services
import { useI18n } from 'vue-i18n';
import { utils } from '@/composables/utils';
import { useStore } from '@/store';

// types
import JobEXService from '@/services/JobEXService';
import { Employer, EmployerJob } from '@/types';

export default defineComponent({
  name: 'EmployerJobModal',
  props: ["employerId"],
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter, IonRow, IonCol, IonAccordion, IonAccordionGroup,
                IonItem, IonLabel, IonIcon, IonButtons, IonButton, IonSpinner,
                IonSearchbar, IonSegment, IonSegmentButton, IonList, IonSelect, IonSelectOption,
                IonCard, IonCardHeader, IonCardSubtitle, IonCardContent, IonChip, IonText, IonCardTitle, IonGrid, IonCheckbox,},
  setup(props) {
    // methods or filters
    const store = useStore();
    const { openModal, presentPrompt, } = utils();
    const { t } = useI18n();

    const user = computed(() => store.state.user);
    const loading = ref(true);
    const userEmployerJobs = ref<any>([]);
    const employers = ref<Employer[]>([]);
    const employerJobs = ref<EmployerJob[]>([]);

    const closeModal = async () => (await modalController.dismiss({}));

    const refreshJobList = () => {
      loading.value = true;
      JobEXService.getEmployers().then((res: Employer[]) => {
        loading.value = false;
        employers.value = res;

        // Jobs
        let jobs: any = [];
        for (const employer of res) {
          const employerJobs = employer.jobs?.map(j => {
            j.employerName = employer.name;
            j.employerWebsite = employer.companyUrl;
            return j;
          })
          jobs = jobs.concat(employerJobs);
        }
        employerJobs.value = jobs;
      });
    }

    onMounted(() => {
      userEmployerJobs.value = user.value.userEmployerJobs || [];
      refreshJobList();
    })

    // return variables & methods to be used in template HTML
    return {
      // icons
      add, close, checkmark, arrowUp, arrowForward, arrowBack,
      trashOutline,

      // variables
      user,
      loading, employerJobs,

      // methods
      t,
      closeModal,
      updateUserJobReaction: (employerJob: EmployerJob, reaction) => {
        const { id: employerJobId, } = employerJob;
        const { programId, yearOfStudy } = user.value;

        presentPrompt("Your CV & CL doc will be shared with the employer", async () => {
          const loading = await loadingController.create({ });
          await loading.present();

          // update DB (submit application)
          await JobEXService.upsertUserEmployerJobs([{ employerJobId, reaction }], { employerJob, programId, yearOfStudy, });
          loading.dismiss();

          // Update user employer jobs
          const existingRow = userEmployerJobs.value.find(uj => uj.employerJobId == employerJobId);
          if (existingRow) existingRow.reaction = reaction;
          else userEmployerJobs.value.push({ employerJobId, reaction, createdAt: new Date() });
          store.commit('updateUser', { userEmployerJobs });
        }, "Confirm?");
      },
      isAppliedJob: (employerJobId) => {
        return userEmployerJobs.value?.find(uj => uj.employerJobId == employerJobId)?.reaction == 'like';
      },
    }
  },
});


// Vue reactivity
import { ref, defineComponent, computed, onMounted, watch, reactive, } from 'vue';

// icons
import { add, close, checkmark, arrowUp,  arrowForward, arrowBack, checkbox, trashOutline,
         thumbsUpOutline, thumbsDownOutline, thumbsUp, thumbsDown, heart, heartOutline,
         chevronBack, chevronForward, repeat, search, pencil, } from 'ionicons/icons';

// components
import { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter, IonRow, IonCol, IonTextarea, IonText,
        IonItem, IonLabel, IonIcon, IonButtons, IonButton, IonReorderGroup, IonReorder,
        modalController } from '@ionic/vue';
import ABProgramSelectModal from '@/components/achievejupas/ABProgramSelectModal.vue';
import ABDisciplineSelectModal from '@/components/pss/ABDisciplineSelectModal.vue';

// composables / services
import { useI18n } from 'vue-i18n';
import { utils } from '@/composables/utils';
import { useStore } from '@/store';
import { Discipline, UserDiscipline } from '@/types';

export default defineComponent({
  name: 'AB4DisciplineResultPageModal',
  props: ["prefilledDisciplines", "targetProfessions", "oldUserDisciplines"],
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter, IonRow, IonCol, IonTextarea, IonText,
                IonItem, IonLabel, IonIcon, IonButtons, IonButton, IonReorderGroup, IonReorder, },
  setup(props, { emit }) {
    // methods or filters
    const { t } = useI18n();
    const store = useStore();
    const { closeModal, doReorder, openModal, processUserItems, syncChosenItems, } = utils();
    
    const user = computed(() => store.state.user);
    const settings = computed(() => store.state.settings);

    const disciplineGroups = computed(() => store.state.allDisciplineGroups);
    const allDisciplines = ref<Discipline[]>(store.getters.shuffledDisciplines);
    const chosenDisciplines = ref<Discipline[]>(props.prefilledDisciplines || []);
    const userDisciplines = ref<UserDiscipline[]>(props.oldUserDisciplines || []);

    const confirmSelect = async (noLoading = false) => {
      await closeModal({
        "chosen": chosenDisciplines.value,
        "userDisciplines": processUserItems(chosenDisciplines.value, userDisciplines.value, [], 'disciplineId', user.value.id),
        noLoading,
      }); // return selected discipline & order here
    };

    // INIT
    onMounted(() => {
      syncChosenItems('disciplineId', chosenDisciplines, userDisciplines, allDisciplines.value);
    })

    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      add, close, checkmark, arrowUp, arrowForward, arrowBack, checkbox, trashOutline,
      thumbsUpOutline, thumbsDownOutline, thumbsUp, thumbsDown, heart, heartOutline, pencil,

      // variables
      disciplineGroups, allDisciplines,
      chosenDisciplines, userDisciplines,
      settings,

      // methods
      t, confirmSelect,
      closeModal,
      doReorder,
      openProgramModal: (specificDiscipline: any) => (openModal(ABProgramSelectModal, { specificDiscipline, readOnly: true })),

      onDeleteChosenDiscipline: (idx, discipline) => {
        chosenDisciplines.value.splice(idx, 1);
        const relatedUserItem = userDisciplines.value.find(us => us.disciplineId == discipline.id);
        if (relatedUserItem) relatedUserItem.reaction = '';
      },

      // Discipline Deck (for choosing disciplines)
      openDisciplineDeckModal: async () => {
        const modal = await modalController.create({
          cssClass: 'tall-modal',
          component: ABDisciplineSelectModal,
          componentProps: {
            prefilledDisciplines: chosenDisciplines.value.slice(),
            oldUserDisciplines: userDisciplines.value.slice() || [],
            targetProfessions: props.targetProfessions,
          }
        });
        modal.onDidDismiss().then(({ data }) => {
          if (data && data.chosen) {
            chosenDisciplines.value = data.chosen;
            userDisciplines.value = data.userDisciplines;
          }
        });
        return modal.present();
      }
    }
  }
});


// Vue reactivity
import { ref, defineComponent, computed, watch, reactive } from 'vue';

// icons
import { add, close, arrowBack, checkmarkCircle, alertCircle, ellipsisHorizontal, ellipsisVertical, pencil, documentOutline, school, } from 'ionicons/icons';

// components
import { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter, IonRow, IonCol,
        IonItem, IonLabel, IonIcon, IonButtons, IonButton, IonSpinner, IonList,
        IonCard, IonCardHeader, IonCardSubtitle, IonCardContent, IonPopover, IonCheckbox,
        IonAccordion, IonAccordionGroup, IonText, IonChip, IonProgressBar, IonSegment, IonSegmentButton,
        loadingController, modalController, alertController, } from '@ionic/vue';
import SchoolTeacherModal from '@/components/teacher/SchoolTeacherModal.vue';
import ServiceModal from '@/components/modals/ServiceModal.vue';
import StudentModal from '@/components/teacher/SchoolStudentModal.vue';
import TeacherRolesModal from '@/components/teacher/TeacherRolesModal.vue';
import RolesInClassesModal from '@/components/teacher/RolesInClassesModal.vue';

// composables
import { useI18n } from 'vue-i18n';
import { utils } from '@/composables/utils';
import { useStore } from '@/store';
import { User } from '@/types';
import config from '@/config';

// services
import TeacherService from '@/services/TeacherService';
import AchieveJUPASService from '@/services/AchieveJUPASService';

export default {
  props: [],
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter, IonRow, IonCol,
    IonItem, IonLabel, IonIcon, IonButtons, IonButton, IonSpinner, IonList,
    IonCard, IonCardHeader, IonCardSubtitle, IonCardContent, IonPopover, IonCheckbox,
    IonAccordion, IonAccordionGroup, IonText, IonChip, IonProgressBar, IonSegment, IonSegmentButton, },
  setup(props) {
    // methods or filters
    const store = useStore();
    const { t } = useI18n();
    const { openModal, sleep, presentToast, presentPrompt, getQRCodeUrl, sendLinkToMyWhatsApp, formatDate,
            getIntakeYearOfDate, getCurrentIntakeYear, openImageModal, getProxyImgLink, copyText, uniqueId, } = utils();

    const user = computed(() => store.state.user);
    const activeClassRoles = () => ((user.value.teacher?.classRoles || []).filter(cr => cr.status != 'removed'));
    
    // Filter service by school roles (or customized wordings)
    const userSchoolRoles = () => ((user.value.teacher?.schoolRoles || "").split(" , ").filter(r => r));

    // School teachers & principals
    const schoolTeachers = computed<User[]>(() => store.state.schoolTeachers);
    const targetTeacherRole = ref("");

    // return variables & methods to be used in template HTML
    return {
      // icons
      add, close, arrowBack,
      checkmarkCircle, alertCircle, ellipsisHorizontal, ellipsisVertical, pencil, documentOutline,

      // variables
      user,
      isPrincipal: computed(() => (user.value.teacher && userSchoolRoles().some(r => ["Principal", "Vice Principal"].includes(r)))),

      // methods
      t,
      formatDate,
      closeModal: async () => (await modalController.dismiss({})),
      openStudentModal: async (initialSection = 2) => (await openModal(StudentModal, { schoolId: user.value.schoolId, initialSection })),

      openImageModal, getProxyImgLink, copyText,
      openServiceModal: async (serviceId: any, event: any = null) => {
        if (serviceId) {
          return await openModal(ServiceModal, { serviceId, event })
        }
      },
      openSchoolTeacherModal: async () => (await openModal(SchoolTeacherModal, { showPhones: true })),
      recentResponses: () => (user.value.teacherResponses || []),

      // Role(s) in class(es)
      activeClassRoles,
      openTeacherRolesModal: async () => {
        return await openModal(TeacherRolesModal, {
          selectedRolesInSchool: userSchoolRoles(),
          prefilledRolesInClasses: activeClassRoles().slice(),
        });
      },
      openRolesInClassesModal: async () => {
        const modal = await modalController.create({
          component: RolesInClassesModal,
          componentProps: { prefilledRolesInClasses: activeClassRoles().slice() },
        });
        modal.onDidDismiss().then(async ({ data }) => {
          if (data && data.rolesInClasses) {
            const loading = await loadingController.create({});
            await loading.present();

            // Check remove class roles (set status to removed)
            const removedClassRoles = activeClassRoles().filter(acr => (data.rolesInClasses.find(cr => cr.id == acr.id) == null));
            const updatedClassRoles = data.rolesInClasses.concat(removedClassRoles.map(rcr => {
              rcr.status = 'removed';
              return rcr;
            }));
            TeacherService.updateTeacher(user.value, { classRoles: updatedClassRoles });
            store.commit('upsertTeacherClassRoles', updatedClassRoles);

            loading.dismiss();
          }
        });
        return modal.present();
      },

      // Progress bar (teacher registration status)
      schoolTeachers, targetTeacherRole,
      checkOpenTeacherModal: async () => {
        //const selectedRole = targetTeacherRole.value;
        await openModal(SchoolTeacherModal, {});
        targetTeacherRole.value = "";
      },
      checkedSchoolTeacherRoles: () => {
        const roles = config.targetTeacherGroups; // Principal / Career Teacher / 5A / ...
        const roleObjs: any = []; // [{ role, relatedTeacher }];
        const teachers = schoolTeachers.value.filter(t => t.teacher?.employmentStatus == "Active"); // only take into account active teachers

        for (const role of roles) {
          let relatedTeacher: any = null;
          switch (role) {
            case "Principal":
              relatedTeacher = teachers.find(t => t.teacher?.schoolRoles?.toLowerCase().split(" , ").includes("principal"));
              break;
            case "Career Teacher":
              relatedTeacher = teachers.find(t => t.teacher?.schoolRoles?.toLowerCase().includes("career"));
              break;
            default: // 5A-E
              relatedTeacher = teachers.find(t => t.teacher?.classRoles?.some(cr => {
                const targetRoles = [
                  "Class teacher",
                  "Ethics and Religious Studies teacher",
                  ...(config.coreSubjects.map(s => `${s} teacher`)),
                ];
                return cr.classes.includes(role) && targetRoles.includes(cr.role) && cr.status == "active";
              }));
              break;
          }
          roleObjs.push({ role, relatedTeacher });
        }
        return {
          progress: roleObjs.filter(ro => (ro.relatedTeacher != null)).length / roles.length, // 100% = all teachers registered
          roleObjs,
        }
      },

      // MOU acceptance
      handleMouAcceptance: (event: any) => {
        event.stopPropagation();
        event.target.checked = false;
        presentPrompt("Confirm you have read and accept the MOU?", async () => {
          const loading = await loadingController.create({ duration: 15000 });
          await loading.present();
          const { schoolId, fullName, teacher, roles, phone, waGroupId, } = user.value;
          const payload = {
            id: `uc${uniqueId()}`,
            schoolId,
            fullName,
            target: 'mou',
            roles: teacher?.schoolRoles || roles,
            response: 'I have read and accept the MOU',
            phone, waGroupId,
          };
          //await AchieveJUPASService.insertUserConsent(payload);
          AchieveJUPASService.insertUserConsent(payload);
          user.value.userConsentRecords?.push(payload);
          event.target.checked = true;
          event.target.disabled = true;
          loading.dismiss();
        });
      },
      isMOUAccepted: computed(() => user.value.userConsentRecords?.find(r => r.target == 'mou' && r.schoolId == user.value.schoolId) != null),
    }
  },
};

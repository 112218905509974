
// Vue reactivity
import { ref, defineComponent, computed, watch, reactive } from 'vue';

// icons
import { add, close, arrowBack, checkmarkCircle, alertCircle, ellipsisHorizontal, ellipsisVertical, pencil, } from 'ionicons/icons';

// components
import { IonItem, IonLabel, IonIcon, IonButtons, IonButton, IonList, IonCard, IonAccordion, IonAccordionGroup, IonText, } from '@ionic/vue';

// composables
import { useI18n } from 'vue-i18n';
import { utils } from '@/composables/utils';
import { useStore } from '@/store';

export default {
  props: [],
  components: {
    IonItem, IonLabel, IonIcon, IonButtons, IonButton, IonList,
    IonCard, IonAccordion, IonAccordionGroup, IonText, },
  setup(props) {
    // methods or filters
    const store = useStore();
    const { t } = useI18n();
    const { getQRCodeUrl, sendLinkToMyWhatsApp, copyText, } = utils();

    const user = computed(() => store.state.user);

    // return variables & methods to be used in template HTML
    return {
      // icons
      add, close, arrowBack,
      checkmarkCircle, alertCircle, ellipsisHorizontal, ellipsisVertical, pencil,

      // variables
      user,

      // methods
      sendLinkToMyWhatsApp,
      getQRCodeUrl,
      copyText,
    }
  },
};

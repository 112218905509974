
// Vue reactivity
import { ref, defineComponent, computed, watch, reactive, onMounted } from 'vue';

// icons
import { add, close, checkmark, arrowUp,  arrowForward, arrowBack, trashOutline, } from 'ionicons/icons';

// components
import { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter, IonRow, IonCol, IonAccordion, IonAccordionGroup,
        IonItem, IonLabel, IonIcon, IonButtons, IonButton,
        IonSearchbar, IonSegment, IonSegmentButton, IonList, IonSelect, IonSelectOption,
        IonCard, IonCardHeader, IonCardSubtitle, IonCardContent, IonChip, IonText, IonCardTitle, IonGrid, IonCheckbox,
        loadingController, modalController, } from '@ionic/vue';

// composables / services
import { useI18n } from 'vue-i18n';
import { utils } from '@/composables/utils';
import { useStore } from '@/store';

// types
import { User, Session, } from '@/types';
import EventService from '@/services/EventService';

export default defineComponent({
  name: 'JobEXEventsModal',
  props: ["anchorEventId", "attendedLesson"],
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter, IonRow, IonCol, IonAccordion, IonAccordionGroup,
                IonItem, IonLabel, IonIcon, IonButtons, IonButton,
                IonSearchbar, IonSegment, IonSegmentButton, IonList, IonSelect, IonSelectOption,
                IonCard, IonCardHeader, IonCardSubtitle, IonCardContent, IonChip, IonText, IonCardTitle, IonGrid, IonCheckbox,},
  setup(props) {
    // methods or filters
    const store = useStore();
    const { openModal, presentPrompt, } = utils();
    const { t } = useI18n();

    const user = computed(() => store.state.user);
    const relatedLesson = computed(() => store.getters.getLessonByAnchorEventId(props.anchorEventId));
    const sessions = computed<Session[]>(() => store.getters.userJobEXIntakeSessions(props.anchorEventId));

    const closeModal = async () => (await modalController.dismiss({}));

    onMounted(() => {
      EventService.getSessionsWithQuota().then(res => {
        store.commit('upsertSessions', res);
      })
    })

    // return variables & methods to be used in template HTML
    return {
      // icons
      add, close, checkmark, arrowUp, arrowForward, arrowBack,
      trashOutline,

      // variables
      user, relatedLesson,
      sessions,

      // methods
      t,
      closeModal,
      isShowEvent: (ev: Session, attendedLesson: any = null) => {
        //return new Date().getFullYear() <= new Date(ev.startTimeStr).getFullYear();
        return (attendedLesson && !user.value.isAdmin) ? ev.userResponse?.confirmed == "Yes"
                              : (new Date(ev.startTimeStr) > new Date(+new Date() - 86400000) || ev.userResponse?.confirmed == "Yes");
      },
    }
  },
});


import { computed, reactive, ref, watch } from 'vue';

// icons
import { personCircle, compass, arrowForward, navigate, settingsOutline, checkmark, arrowBack,
        add, close, checkmarkCircle, alertCircle, pencil, peopleOutline, qrCodeOutline,
        createOutline, calendarOutline, calendarClearOutline, play, home, } from 'ionicons/icons';

// components
import { IonPage, IonToolbar, IonContent, IonModal, IonHeader, IonTitle,
        IonGrid, IonCol, IonRow, IonSpinner, IonNote, IonSegment, IonSegmentButton, IonLabel,
        IonButtons, IonButton, IonIcon, IonBadge, IonChip, IonList, IonItem,
        onIonViewDidEnter,
        onIonViewWillLeave,
        onIonViewDidLeave, } from '@ionic/vue';
import Slides from '@/components/shared/Slides.vue';
import LoginPage from '@/pages/LoginPage.vue';

// University
import ClientActionCardList from '@/components/client/ClientActionCardList.vue';
import UniStudentActionCardList from '@/components/university/UniStudentActionCardList.vue';

// Secondary School
import SecStudentActionCardList from '@/components/secondary/SecStudentActionCardList.vue';
import TeacherActionCardList from '@/components/teacher/TeacherActionCardList.vue';

// FDMT Internal Staff
import FDMTStaffActionCardList from '@/components/FDMTStaffActionCardList.vue';

// composables
import { useI18n } from 'vue-i18n';
import { useStore } from '@/store';
import { utils } from '@/composables/utils';
import { useRoute } from 'vue-router';
import config from '@/config';

export default {
  name: 'HomePage',
  components: { IonPage, IonToolbar, IonContent, IonModal, IonHeader, IonTitle,
                IonGrid, IonCol, IonRow, IonSpinner, IonNote, IonSegment, IonSegmentButton, IonLabel,
                IonButtons, IonButton, IonIcon, IonBadge, IonChip, IonList, IonItem,
                TeacherActionCardList, ClientActionCardList, SecStudentActionCardList, UniStudentActionCardList,
                FDMTStaffActionCardList, Slides, },
  setup() {
    // methods
    const { t } = useI18n();
    const store = useStore();
    const { openImageModal, openModal, getProxyImgLink, getQRCodeUrl, copyText, getIntakeYearOfDate, getCurrentIntakeYear, openBookingModal, } = utils();

    // state variables
    const forceReload = ref(false);
    const loadingData = computed(() => store.state.loadingUser || store.state.loadingData);
    const loadingPortalData = computed(() => store.state.loadingUser || store.state.loadingData || store.state.loadingPortalData);
    const settings = computed(() => store.state.settings);
    const user = computed(() => store.state.user);
    const selectedView = ref<any>("");
    const uniStudentSelectedView = ref<any>("internship");

    // Route params
    const route = useRoute();
    const { view: prefilledView, viewType: prefilledViewType } = route.query;

    // Registration Ack
    const ackAppliedEvent = computed(() => store.state.ackAppliedEvent);
    const ackInterestedService = computed(() => store.state.ackInterestedService);

    /**
     * INIT
     */
    const getInitialUserView = () => {
      const { teacher, isSecondaryStudent, isUniversityStudent, isUniversityClient, isEmployer, } = user.value;
      if (isUniversityStudent) return 'university-student';
      if (isSecondaryStudent) return 'secondary-school-student';
      if (isUniversityClient) return 'university-client';
      if (isEmployer) return 'employer';
      if (teacher) return 'teacher';
      return prefilledView || 'home' // visitor
    }
    const checkAddBlueBg = () => {
      if (selectedView.value == 'home' || !user.value.id) document.body.classList.add('blue-bg');
      else document.body.classList.remove('blue-bg');
    }
    onIonViewDidEnter(() => {
      forceReload.value = true;
      setTimeout(() => forceReload.value = false, 200);

      selectedView.value = getInitialUserView();
      checkAddBlueBg();
    });

    // Set initial view
    watch(user, () => {
      selectedView.value = getInitialUserView();
      checkAddBlueBg();
    });
    watch(selectedView, () => {
      checkAddBlueBg();

      // Switch role for admin users
      //if (user.value.isAdmin && ['teacher', 'secondary-school-student', 'university-student', 'university-client'].includes(selectedView.value)) {
      if (user.value.isAdmin) {
        let baseParams = { teacher: null, isSecondaryStudent: false, isUniversityStudent: false, isUniversityClient: false };
        if (selectedView.value == 'teacher') {
          store.commit('updateUser', { ...baseParams, teacher: store.state.teacher }); // Teacher view
          store.dispatch('getSchoolUsers', user.value.schoolId); // retrieve school users for teacher role
        } else {
          switch (selectedView.value) {
            case 'secondary-school-student':
              baseParams = { ...baseParams, isSecondaryStudent: true }; // Student view
              break;
            case 'university-student':
              baseParams = { ...baseParams, isUniversityStudent: true };
              break;
            case 'university-client':
              baseParams = { ...baseParams, isUniversityClient: true };
              store.dispatch('getProgramUsers', user.value.programId); // TBC: can browse list of university students
              break;
          }
          store.commit('updateUser', baseParams);
        }
        localStorage.setItem(config.sessionAppRoleField, selectedView.value);
      }
    })

    const handleScroll = (ev) => {
      const banner: any = document.querySelector('.parallax-bg');
      if (banner) {
        const scrollY = ev.detail.scrollTop;
        //banner.style.transform = `scale(1.5) translateY(${rate}px)`;
        const rate = scrollY * 0.1; // Adjust speed by changing this value
        banner.style.transform = `translate3d(0, ${rate}px, 0)`
      }
    }

    return {
      // icons
      personCircle, compass, arrowForward, navigate, settingsOutline, checkmark, arrowBack,
      add, close, checkmarkCircle, alertCircle, pencil, peopleOutline, qrCodeOutline,
      createOutline, calendarOutline, calendarClearOutline, play, home,

      // variables
      selectedView, uniStudentSelectedView, // Toggle view
      forceReload, loadingData, loadingPortalData,
      user, settings,
      ackAppliedEvent, ackInterestedService,
      prefilledViewType,

      // methods
      t, getProxyImgLink, openImageModal, getQRCodeUrl, copyText,
      handleScroll,

      onClickWhatsAppButton: () => {
        store.dispatch('setUserJoinedWAGroup');
        user.value.userInWaGroup = true;
      },

      openLoginModal: (prefilledRole) => {
        openModal(LoginPage, { prefilledRole }, "login-modal");
      },

      // Special tab conditions
      isSecondaryStudent: computed(() => user.value.isSecondaryStudent && !user.value.isAdmin), // hide other tabs for secondary students
      isFDMTStaff: computed(() => user.value.isAdmin), // for mega student list
    }
  }
}

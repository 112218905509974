import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e6aa1dfa"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "ion-padding-start",
  slot: "content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_item, {
      lines: "full",
      slot: "header",
      style: {"--min-height":"16px"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_label, {
          class: "ion-text-wrap",
          style: {"color":"#888888","margin":"0"}
        }, {
          default: _withCtx(() => [
            _createElementVNode("b", null, [
              _createElementVNode("span", null, _toDisplayString($props.group), 1)
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ], 64))
}
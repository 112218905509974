
// vue
import { computed } from '@vue/reactivity';
import { defineComponent, onMounted, ref,  } from 'vue';

// icons
import { add, close } from 'ionicons/icons';

// components
import {
  IonInput,
  IonIcon,
  IonFooter,
  IonButton,
  IonButtons,
  IonCheckbox,
  IonContent,
  IonHeader,
  IonItem, IonLabel,
  IonList,
  IonTitle,
  IonSearchbar,
  IonToolbar,
  IonAccordion, IonAccordionGroup, IonSpinner,
  modalController
} from '@ionic/vue';
import config from '@/config';

// composables
import { useStore } from '@/store';
import { utils } from '@/composables/utils';
import { useI18n } from 'vue-i18n';
import PortalService from '@/services/PortalService';

export default defineComponent({
  name: 'CVExamplesModal',
  props: ["chosenProfession"],
  components: {
    IonInput,
    IonIcon,
    IonFooter,
    IonButton,
    IonButtons,
    IonCheckbox,
    IonContent,
    IonHeader,
    IonItem, IonLabel,
    IonList,
    IonTitle,
    IonSearchbar,
    IonToolbar,
    IonAccordion, IonAccordionGroup, IonSpinner,
  },
  setup(props) {
    const { closeModal, presentToast, copyText, } = utils();
    const { t } = useI18n();

    const store = useStore();

    const cvBestExamples = ref([]);
    const loadingExamples = ref(false);

    onMounted(() => {
      loadingExamples.value = true;

      PortalService.getCvCl(props.chosenProfession?.id).then(res => {
        const { curriculum_vitae: cv, cover_letter: cl } = res;
        const headlines = cv.match(/<em>([\S\s]*?)<\/em>/g);
        const bulletPointGroups = cv.match(/<ul>([\S\s]*?)<\/ul>/g);
        const bestExamples: any = [];
        if (headlines && bulletPointGroups) {
          for (let i = 0; i < headlines.length; i++) {
            const bulletPoints = bulletPointGroups[i].match(/<li>([\S\s]*?)<\/li>/g);
            bestExamples.push({
              headline: headlines[i].replace(/<[^>]*>?/gm, '').replace(/&#42;/g, "*"),
              bulletPoints: bulletPoints.map((pt) => pt.replace(/<[^>]*>?/gm, '')),
            })
          }
        }
        cvBestExamples.value = bestExamples;
        loadingExamples.value = false;
      });
    })

    return {
      // icon
      add, close,

      // variables
      loadingExamples, cvBestExamples,

      // methods
      t, closeModal,

      copyText,
    };
  },
});

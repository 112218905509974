
import { computed, onMounted, reactive, ref, watch } from 'vue';

// icons
import { personCircle, compass, arrowForward, navigate, settingsOutline, checkmark, arrowBack,
        add, close, checkmarkCircle, alertCircle, pencil, peopleOutline, qrCodeOutline,
        createOutline, calendarOutline, calendarClearOutline, play, } from 'ionicons/icons';

// components
import { IonPage, IonToolbar, IonContent, IonModal, IonHeader, IonTitle,
        IonCard, IonCardTitle, IonCardSubtitle, IonCardHeader, IonCardContent, IonSegment, IonSegmentButton,
        IonGrid, IonCol, IonRow, IonSpinner, IonNote, IonButtons, IonButton, IonIcon,
        IonBadge, IonChip, IonList, IonItem, IonLabel, IonAccordion, IonAccordionGroup,
        loadingController, modalController, toastController, } from '@ionic/vue';
import ActionAccordionGroup from '@/components/shared/ActionAccordionGroup.vue';

// Secondary School
import AchieveJUPASResultPageModal from '@/components/achievejupas/AchieveJUPASResultPageModal.vue';
import AB4ProfessionResultPageModal from '@/components/secondary/AB4ProfessionResultPageModal.vue';
import AB4DisciplineResultPageModal from '@/components/secondary/AB4DisciplineResultPageModal.vue';
import WorkEventsModal from '@/components/secondary/events/WorkEventsModal.vue';
import SLPModal from '@/components/secondary/ABSLPModal.vue';
import ABProfessionSelectModal from '@/components/pss/profession/ABProfessionSelectModal.vue';

// types
import { Session, Discipline, Profession, Program, Step1Option, User, Service, } from '@/types';

// composables
import { useI18n } from 'vue-i18n';
import { useStore } from '@/store';
import { utils } from '@/composables/utils';

// services
import ABSService from '@/services/ABSService';

export default {
  components: { IonPage, IonToolbar, IonContent, IonModal, IonHeader, IonTitle,
                IonCard, IonCardTitle, IonCardSubtitle, IonCardHeader, IonCardContent, IonSegment, IonSegmentButton,
                IonGrid, IonCol, IonRow, IonSpinner, IonNote, IonButtons, IonButton, IonIcon,
                IonBadge, IonChip, IonList, IonItem, IonLabel, IonAccordion, IonAccordionGroup, ActionAccordionGroup,
                AchieveJUPASResultPageModal, },
  setup() {
    // methods
    const { t } = useI18n();
    const store = useStore();
    const { openImageModal, openModal, getF3YearDSE, getF4YearDSE, getF5YearDSE, getF6YearDSE, promptRollCallCode,
            getProxyImgLink, getQRCodeUrl, copyText, openServiceModal, } = utils();

    // state variables
    const loadingData = computed(() => store.state.loadingUser || store.state.loadingData);
    const loadingPortalData = computed(() => store.state.loadingUser || store.state.loadingData || store.state.loadingPortalData);
    const settings = computed(() => store.state.settings);
    const user = computed(() => store.state.user);
    const userRelatedSchool = computed(() => store.getters.userRelatedSchool);

    // View switcher
    const selectedViews = reactive({
      form: "F5-6",
      type: "AchieveJUPAS",
    }); // mainly for switching between AchieveJUPAS & Resources

    // Work Services / Sessions
    const workServices = computed<Service[]>(() => store.getters.getServicesByTypes(["Work"]));
    const workVisitEvents = computed<Session[]>(() => store.getters.getSessionsByAnchorEventId('work-campus-visit'));

    const getServices = (targetNature: any = null) => {
      const yearDSE = Number(user.value.yearDSE);
      const targetForms = (yearDSE == getF6YearDSE() ? ['F.6', 'F.5'] : (yearDSE == getF4YearDSE() ? ['F.4'] : ['F.5']));
      const targetServices = (workServices.value || []).filter(s => {
        if (targetNature && s.nature != targetNature) return false; // Workshop / Visit
        if (store.getters.getLastAppliedSessionByServiceId(s.id)) return true; // contain applied session
        if (s.status == 'Inactive' || !s.nature) return false;
        
        // only include services related to the form
        if (targetForms && s.targetForms && s.targetForms.length > 0 && !s.targetForms.some(f => targetForms.includes(f))) return false;
        
        // skip because nominated by teachers (TBC: align layout with teachers?)
        if (['Talk at your school', 'Video'].includes(s.nature)) return false;

        return s.targetSchoolBands.length == 0 || userRelatedSchool.value.id == 'beacon1' ||
                s.targetSchoolBands.includes(userRelatedSchool.value.band);
      }).map(s => {
        s.lastAppliedSession = store.getters.getLastAppliedSessionByServiceId(s.id); // SLP visit / workshop
        return s;
      });
      return targetServices;
    };
    const getWorkshopServicesByUserLevel = (userLevel) => {
      const services = getServices('Workshop');
      const isShortlisted = (lastAppliedSession) => {
        if (!lastAppliedSession) return false;
        const { interviewStatus, response } = lastAppliedSession.userResponse;
        return interviewStatus?.includes('Accepted') || ['Attended', 'Confirmed'].includes(response);
      }
      switch (userLevel) {
        case 'eligible-work-applicant':
          return services.filter(s => !isShortlisted(s.lastAppliedSession));
        case 'shortlisted-work-applicant':
          return services.filter(s => isShortlisted(s.lastAppliedSession));
      }
      return services;
    }

    /**
     * AB3 / AB4
     */
    const userABSIntakeSessions = computed<Session[]>(() => store.getters.userABSIntakeSessions);
    const isAB4User = computed(() => store.getters.isAB4User);
    const userChoices = reactive({
      chosenDisciplines: [] as Discipline[],
      step1ProfessionReason: "",
      step1ProfessionAction: "",
      step2DisciplineReason: "",
      step2DisciplineAction: "",
      chosenProfessions: [] as Profession[],
      chosenOptions: [] as Step1Option[],
    });
    const getUserChoiceUpdatingObj = () => {
      const { chosenOptions, chosenProfessions, step1ProfessionReason, step1ProfessionAction, step2DisciplineReason, step2DisciplineAction, chosenDisciplines, } = userChoices;
      return {
        step1OptionIds: chosenOptions.map(o => o.id).join(" , "),
        step1OrderedProfessionIds: chosenProfessions.map(p => p.id).join(" , "),
        step1ProfessionReason: step1ProfessionReason,
        step1ProfessionAction: step1ProfessionAction,
        step2DisciplineIds: chosenDisciplines.map(d => d.id).join(" , "),
        step2DisciplineReason: step2DisciplineReason,
        step2DisciplineAction: step2DisciplineAction,
      };
    }
    const someAnswersUpdated = () => {
      const updatingObj = getUserChoiceUpdatingObj();
      return Object.keys(updatingObj).some(field => (user.value[field] != updatingObj[field]));
    };
    const saveUserChoices = async (noLoading = false) => {
      if (someAnswersUpdated()) {
        const loading = await loadingController.create({});
        if (!noLoading) await loading.present();

        // Update DB
        const updatingObj = getUserChoiceUpdatingObj();
        ABSService.saveUserChoices(updatingObj);

        // Update Store
        const { chosenOptions, chosenProfessions, step1ProfessionReason, step1ProfessionAction, step2DisciplineReason, step2DisciplineAction, chosenDisciplines, } = userChoices;
        store.commit('updateUser', {
          ...updatingObj,
          lastSelectedStep1Options: chosenOptions.slice(),
          lastSelectedProfessions: chosenProfessions.slice(),
          step1ProfessionReason: step1ProfessionReason,
          step1ProfessionAction: step1ProfessionAction,
          step2DisciplineReason: step2DisciplineReason,
          step2DisciplineAction: step2DisciplineAction,
          lastSelectedDisciplines: chosenDisciplines.slice(),
        });
        loading.dismiss();
        if (!noLoading) {
          const toast = await toastController.create({
            message: t('successSave'),
            duration: 3000,
            position: 'top',
          });
          toast.present();
        }
      }
    };

    /**
     * Professions
     */
    const openProfessionSelectModal = async () =>  {
      const modal = await modalController.create({
        cssClass: 'tall-modal',
        component: AB4ProfessionResultPageModal,
        componentProps: {
          prefilledProfessions: userChoices.chosenProfessions.slice(),
          oldUserProfessions: user.value.userProfessions?.slice() || [],
        },
        backdropDismiss: false,
      });
      modal.onDidDismiss().then(({ data }) => {
        if (data && data.selectedProfessions) {
          // Chosen professions
          userChoices.step1ProfessionReason = data.selectedProfessions[0]?.reason || "";
          userChoices.step1ProfessionAction = data.selectedProfessions[0]?.action || "";
          userChoices.chosenProfessions = data.selectedProfessions;
          saveUserChoices(data.noLoading);

          // User profession reactions
          ABSService.upsertUserProfessions(data.userProfessions);
          store.commit('updateUser', { userProfessions: data.userProfessions });
        }
      });
      return modal.present();
    }

    /**
     * Disciplines
     */
    const openDisciplineSelectModal = async () =>  {
      const modal = await modalController.create({
        cssClass: 'tall-modal',
        component: AB4DisciplineResultPageModal,
        componentProps: {
          prefilledDisciplines: userChoices.chosenDisciplines.slice(),
          targetProfessions: userChoices.chosenProfessions.slice(0, 5),
          oldUserDisciplines: user.value.userDisciplines?.slice() || [],
        }
      });
      modal.onDidDismiss().then(({ data }) => {
        if (data && data.chosen) {
          // Chosen disciplines
          userChoices.step2DisciplineReason = data.chosen[0]?.reason || "";
          userChoices.step2DisciplineAction = data.chosen[0]?.action || "";
          userChoices.chosenDisciplines = data.chosen;
          saveUserChoices(data.noLoading);

          // User discipline reactions
          ABSService.upsertUserDisciplines(data.userDisciplines);
          store.commit('updateUser', { userDisciplines: data.userDisciplines });
        }
      });
      return modal.present();
    }

    /**
     * INIT
     */
    const loadSavedUserChoices = (user: User) => {
      if (user.id) {
        userChoices.chosenOptions = user.lastSelectedStep1Options || [];
        userChoices.chosenProfessions = user.lastSelectedProfessions || [];
        userChoices.step1ProfessionReason = user.step1ProfessionReason || "";
        userChoices.step1ProfessionAction = user.step1ProfessionAction || "";
        userChoices.chosenDisciplines = user.lastSelectedDisciplines || [];
        userChoices.step2DisciplineReason = user.step2DisciplineReason || "";
      }
    }
    onMounted(() => {
      loadSavedUserChoices(user.value);
    });
    watch(loadingPortalData, () => { // triggered only when direct access to this page
      loadSavedUserChoices(user.value);
    });

    return {
      // icons
      personCircle, compass, arrowForward, navigate, settingsOutline, checkmark, arrowBack,
      add, close, checkmarkCircle, alertCircle, pencil, peopleOutline, qrCodeOutline,
      createOutline, calendarOutline, calendarClearOutline, play,

      // variables
      loadingData, loadingPortalData,
      user, userChoices, settings,

      // methods
      t, getProxyImgLink, openImageModal, getQRCodeUrl, copyText,
      isUserAttendedLesson: (lessonId) => (user.value.sessionResponses?.find(r => (r.lessonId == lessonId && r.attended == 'Yes'))),

      // modals
      openProfessionSelectModal, openDisciplineSelectModal,
      openWorkEventsModal: async (anchorEvId, showTeacherActions = false) => await openModal(WorkEventsModal, {
        showTeacherActions, chosenDisciplines: userChoices.chosenDisciplines.slice(0, 5), anchorEvId,
      }),
      openSLPModal: async (isPreview = false) => {
        const ev = store.getters.getLastAttendedSession;
        return await openModal(SLPModal, { isPreview, ev, serviceId: ev?.serviceId, relatedProgramId: ev?.relatedProgramId, relatedClientId: user.value.clientId }, "", false)
      },

      // AB3 / AB4 Functions
      isAB4User,
      filteredAB4Sessions: (targetAnchorEvId) => (userABSIntakeSessions.value.filter(s => s.anchorEventId == targetAnchorEvId)),
      isShowEvent: (ev: Session) => {
        const evStart = new Date(ev.startTimeStr);
        return (evStart > new Date(+new Date() - 5*86400000) && evStart < new Date(+new Date() + 30*86400000)) || ev.userResponse?.confirmed == "Yes"
      },
      openAB3Modal: async () =>  {
        const modal = await modalController.create({
          cssClass: 'tall-modal',
          component: AchieveJUPASResultPageModal,
          componentProps: { isAB3: true, }
        });
        return modal.present();
      },
      isSelectedProfessions: () => (userChoices.chosenProfessions.length > 0),
      isSelectedDisciplines: () => (userChoices.chosenDisciplines.length > 0),

      /*--
        Secondary School Student
      */
      getF4YearDSE, getF5YearDSE, getF6YearDSE,
      workServices, openServiceModal, workVisitEvents,
      getServices, getWorkshopServicesByUserLevel,
      getServiceName: (service) => {
        const { lastAppliedSession, name } = service;
        return lastAppliedSession ? `${name} (${lastAppliedSession.date} ${lastAppliedSession.startTime})` : name;
      },
      getServicesByIds: (ids) => (workServices.value || []).filter(s => ids.includes(s.id)), // TMP: for demo

      // Other
      getSessionById: (sessionId: any) => (store.getters.getSessionById(sessionId)),

      // AI Chatbot (for AI competition - image generation)
      openAIProfessionImageModal: () => {
        //return openModal(ChatbotModal, { isImageBot: true }); // image bot
        return openModal(ABProfessionSelectModal, { isAIImageCompetition: true, }); // image bot
      },

      // View switcher
      selectedViews,
    }
  },
}

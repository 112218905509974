
import { computed, onMounted, reactive, ref, watch } from 'vue';

// icons
import { personCircle, compass, arrowForward, navigate, settingsOutline, checkmark, arrowBack,
        add, close, checkmarkCircle, alertCircle, pencil, peopleOutline, qrCodeOutline,
        createOutline, calendarOutline, calendarClearOutline, play, } from 'ionicons/icons';

// components
import { IonPage, IonToolbar, IonContent, IonModal, IonHeader, IonTitle,
        IonCard, IonCardTitle, IonCardSubtitle, IonCardHeader, IonCardContent,
        IonGrid, IonCol, IonRow, IonSpinner, IonNote, IonButtons, IonButton, IonIcon,
        IonBadge, IonChip, IonList, IonItem, IonLabel, IonAccordion, IonAccordionGroup,
        loadingController, modalController, toastController, } from '@ionic/vue';
import ProfessionModal from '@/components/pss/ProfessionModal.vue';

// University
import UniProfessionResultPageModal from '@/components/university/UniProfessionResultPageModal.vue';
import UniProfessionDeckModal from '@/components/university/UniProfessionDeckModal.vue';
import UniEmployerSelectModal from '@/components/university/EmployerSelectModal.vue';
import JobEXEventsModal from '@/components/university/JobEXEventsModal.vue';
import CVModal from '@/components/university/CVModal.vue';
import CLModal from '@/components/university/CLModal.vue';
import EmployerJobModal from '@/components/university/EmployerJobModal.vue';

// types
import { Session, Profession, } from '@/types';

// composables
import { useI18n } from 'vue-i18n';
import { useStore } from '@/store';
import { utils } from '@/composables/utils';

// services
import ABSService from '@/services/ABSService';
import JobEXService from '@/services/JobEXService';

export default {
  name: 'HomePage',
  components: { IonPage, IonToolbar, IonContent, IonModal, IonHeader, IonTitle,
                IonCard, IonCardTitle, IonCardSubtitle, IonCardHeader, IonCardContent,
                IonGrid, IonCol, IonRow, IonSpinner, IonNote, IonButtons, IonButton, IonIcon,
                IonBadge, IonChip, IonList, IonItem, IonLabel, IonAccordion, IonAccordionGroup, },
  setup() {
    // methods
    const { t } = useI18n();
    const store = useStore();
    const { openImageModal, openModal, getProxyImgLink, getQRCodeUrl, copyText, getIntakeYearOfDate, getCurrentIntakeYear, openBookingModal, } = utils();

    // state variables
    const loadingData = computed(() => store.state.loadingUser || store.state.loadingData);
    const loadingPortalData = computed(() => store.state.loadingUser || store.state.loadingData || store.state.loadingPortalData);
    const user = computed(() => store.state.user);

    // --------------- University / jobEX --------------- //
    const userRelatedJobEX = computed(() => store.getters.userRelatedJobEX);
    const userJobEXIntakeSessions = computed<Session[]>(() => store.getters.userJobEXIntakeSessions());
    const userCareerPlan = computed(() => store.state.userCareerPlan); // saved on DB

    const careerPlan = reactive({
      orderedProfessions: [] as Profession[], // sorted by students (filtered professions)
      targetSegments: [],
      filteredSegments: [],
      targetEmployers: [],

      claim1: "",
      claim2: "",
      claim3: "",
    });
    const refreshTargetSegments = async () => {
      // Update list of selected segments (usually after changing professions)
      careerPlan.filteredSegments = store.getters.getRelatedSegmentsByProfessionId(careerPlan.orderedProfessions[0]?.id);
      const filteredSegmentIds = careerPlan.filteredSegments.map((s: any) => s.id);
      careerPlan.targetSegments = careerPlan.targetSegments.filter((s: any) => filteredSegmentIds.includes(s.id));
    }

    const loadSavedCareerPlan = (plan: any) => {
      if (plan && plan.orderedProfessions) {
        careerPlan.orderedProfessions = plan.orderedProfessions;
        careerPlan.targetSegments = plan.targetSegments;
        careerPlan.targetEmployers = plan.targetEmployers;
        careerPlan.claim1 = plan.claim1;
        careerPlan.claim2 = plan.claim2;
        careerPlan.claim3 = plan.claim3;

        refreshTargetSegments();
      }
    }
    const saveCareerPlan = async (noLoading = false) => {
      const loading = await loadingController.create({});
      if (!noLoading) await loading.present();
      JobEXService.saveUserCareerPlan(careerPlan);
      store.commit('updateUserCareerPlan', careerPlan);
      if (!noLoading) {
        loading.dismiss();
        const toast = await toastController.create({
          message: t('successSave'),
          duration: 3000,
          position: 'top',
        });
        toast.present();
      }
    }
    const jobEXOpenProfessionSelectModal = async () =>  {
      const modal = await modalController.create({
        component: UniProfessionResultPageModal,
        componentProps: {
          prefilledProfessions: careerPlan.orderedProfessions.slice(),
          oldUserProfessions: user.value.userProfessions?.slice() || [],
          oldUserSegments: user.value.userSegments?.slice() || [],
        },
        backdropDismiss: false,
      });
      modal.onDidDismiss().then(({ data }) => {
        if (data && data.selectedProfessions) {
          careerPlan.orderedProfessions = data.selectedProfessions;
          //if (!user.value.isAdmin) saveCareerPlan(data.noLoading); // save to DB
          saveCareerPlan(data.noLoading); // save to DB

          ABSService.upsertUserProfessions(data.userProfessions);
          ABSService.upsertUserSegments(data.userSegments);
          store.commit('updateUser', { userProfessions: data.userProfessions, userSegments: data.userSegments });
        }
      });
      return modal.present();
    }

    const jobEXOpenEmployerSelectModal = async () =>  {
      const modal = await modalController.create({
        component: UniEmployerSelectModal,
        componentProps: {
          prefilledSegments: careerPlan.targetSegments.slice(),
          segmentOptions: careerPlan.filteredSegments.slice(),
          prefilledEmployers: careerPlan.targetEmployers.slice(),
          prefilledClaim1: careerPlan.claim1,
          prefilledClaim2: careerPlan.claim2,
          prefilledClaim3: careerPlan.claim3,
        }
      });
      modal.onDidDismiss().then(({ data }) => {
        if (data && data.selectedEmployers) {
          careerPlan.targetSegments = data.selectedSegments;
          careerPlan.targetEmployers = data.selectedEmployers;
          careerPlan.claim1 = data.claim1;
          careerPlan.claim2 = data.claim2;
          careerPlan.claim3 = data.claim3;
          if (!user.value.isAdmin) saveCareerPlan(); // save to DB
        }
      });
      return modal.present();
    }

    onMounted(() => {
      loadSavedCareerPlan(userCareerPlan.value);
    });
    watch(userCareerPlan, (currUserCareerPlan: any) => { // triggered only when direct access to this page
      loadSavedCareerPlan(currUserCareerPlan);
    });

    return {
      // icons
      personCircle, compass, arrowForward, navigate, settingsOutline, checkmark, arrowBack,
      add, close, checkmarkCircle, alertCircle, pencil, peopleOutline, qrCodeOutline,
      createOutline, calendarOutline, calendarClearOutline, play,

      // variables
      loadingData, loadingPortalData,
      user,

      // methods
      t, getProxyImgLink, openImageModal, getQRCodeUrl, copyText,

      isUserAttendedLesson: (lessonId) => (user.value.sessionResponses?.find(r => (r.lessonId == lessonId && r.attended == 'Yes'))),
      isShowEvent: (ev: Session) => {
        const evStart = new Date(ev.startTimeStr);
        return (evStart > new Date(+new Date() - 5*86400000) && evStart < new Date(+new Date() + 30*86400000)) || ev.userResponse?.confirmed == "Yes"
      },

      // --------------- University / jobEX --------------- //
      userRelatedJobEX,
      careerPlan, saveCareerPlan,
      jobEXOpenProfessionSelectModal,
      jobEXOpenEmployerSelectModal,
      getProfessionNameById: (id: any) => (store.state.allProfessions.find(p => p.id == id)?.name),
      filteredJobEXIntakeSessions: (targetAnchorEvId) => {
        return userJobEXIntakeSessions.value.filter(s => s.anchorEventId == targetAnchorEvId);
      },
      isUserUniYear1: () => {
        const { yearOfStudy, createdAt } = user.value;
        return yearOfStudy == 'Year 1' && getIntakeYearOfDate(createdAt) == getCurrentIntakeYear();
      },
      openYr1ExploreProfessionModal: async () => (await openModal(UniProfessionDeckModal, { isYear1: true })),
      openJobEXEventsModal: async (targetAnchorEvId, attendedLessonAt = null) => {
        return openModal(JobEXEventsModal, {
          attendedLesson: !!attendedLessonAt,
          anchorEventId: targetAnchorEvId,
        });
      },
      jobEXIsSelectedProfessions: () => {
        const { userProfessions } = user.value;
        const profs = (userProfessions || []).filter(up => up.reaction == 'like').sort((a,b) => (Number(a.order)-Number(b.order)));
        return profs.length >= 2 && profs.slice(0, 2).every(up => (user.value.userSegments?.some(us => (us.reaction == 'like' && us.professionId == up.professionId))));
      },
      jobEXIsSelectedEmployers: () => (careerPlan.targetEmployers.length > 0),
      openProfessionModal: async (professionId: any, expandAlumniSection = false) => await openModal(ProfessionModal, { professionId, useBackButton: true, expandAlumniSection }),
      openCVModal: async () => (await openModal(CVModal, {})),
      openCLModal: async () => (await openModal(CLModal, {})),
      openEmployerJobModal: async () => await openModal(EmployerJobModal, {}), // Employer / JD for jobEX guest lectures
      openBookingModal,
    }
  },
}


// Vue reactivity
import { ref, defineComponent, computed, watch, reactive, onMounted, onUnmounted, onBeforeUnmount } from 'vue';

// icons
import { add, close, checkmark, arrowUp, arrowForward, arrowBack, trashOutline,
        thumbsUpOutline, thumbsDownOutline, thumbsUp, thumbsDown, heart, heartOutline,
        chevronBack, chevronForward, repeat, search, createOutline, pencil, refresh, } from 'ionicons/icons';

// components
import { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter, IonRow, IonCol, IonReorder, IonReorderGroup,
        IonList, IonItem, IonLabel, IonIcon, IonButtons, IonButton, IonTextarea, IonFab, IonFabButton,
        isPlatform, modalController, } from '@ionic/vue';
import ProfessionModal from '@/components/pss/ProfessionModal.vue';
import UniProfessionDeckModal from '@/components/university/UniProfessionDeckModal.vue';
import SectorModal from '@/components/pss/SectorModal.vue';

// composables / services
import { useI18n } from 'vue-i18n';
import { utils } from '@/composables/utils';
import { useStore } from '@/store';
import { Profession, ProfessionTab, Segment, UserProfession, UserSegment } from '@/types';

export default defineComponent({
  name: 'UniProfessionResultPageModal',
  props: ["prefilledProfessions", "oldUserProfessions", "oldUserSegments", "isYear1", "isSecSchoolStudents"],
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter, IonRow, IonCol, IonReorder, IonReorderGroup,
                IonList, IonItem, IonLabel, IonIcon, IonButtons, IonButton, IonTextarea, IonFab, IonFabButton, },
  setup(props) {
    // methods or filters
    const store = useStore();
    const { openModal, closeModal, doReorder, syncChosenItems, linkNestedChosenItems, processUserItems, } = utils();
    const { t } = useI18n();

    const userSegments = ref<UserSegment[]>(props.oldUserSegments || []);
    const allSegments = computed<Segment[]>(() => store.state.allSegments);

    const selectedProfessions = ref<Profession[]>(props.prefilledProfessions || []);
    const userProfessions = ref<UserProfession[]>(props.oldUserProfessions || []);
    const allProfessions = ref<Profession[]>(store.state.allProfessions);

    const confirmSelect = async (noLoading = false) => {
      for (const prof of selectedProfessions.value) {
        if (prof.selectedSegments) {
          userSegments.value = processUserItems(prof.selectedSegments, userSegments.value, [], 'segmentId', store.state.user.id)
        }
      }
      await closeModal({
        "selectedProfessions": selectedProfessions.value, noLoading,
        "userProfessions": userProfessions.value,
        "userSegments": userSegments.value,
      }); // return selected items & order here
    };
    const openProfessionModal = async (professionId: any) => await openModal(ProfessionModal, { professionId, useBackButton: true });

    onMounted(() => {
      // Prefill with previously written reasons
      syncChosenItems('professionId', selectedProfessions, userProfessions, allProfessions.value);

      // Custom: link selected segments to professions
      linkNestedChosenItems(userSegments, selectedProfessions, 'professionId', allSegments, 'segmentId', 'selectedSegments');
    })

    // return variables & methods to be used in template HTML
    return {
      // icons
      add, close, checkmark, arrowUp, arrowForward, arrowBack, trashOutline,
      thumbsUpOutline, thumbsDownOutline, thumbsUp, thumbsDown, heart, heartOutline,
      createOutline, pencil, refresh,

      // variables
      selectedProfessions, userProfessions,

      // methods
      t,
      doReorder,
      confirmSelect, closeModal,
      
      openProfessionModal,
      openSegmentModal: async (segment: any) => (await openModal(SectorModal, { sectorId: segment.sectorId, segmentId: segment.id })),

      openUniProfessionDeckModal: async () => {
        const modal = await modalController.create({
          component: UniProfessionDeckModal,
          componentProps: {
            prefilledProfessions: selectedProfessions.value.slice(),
            oldUserProfessions: userProfessions.value.slice() || [],
            isSecSchoolStudents: props.isSecSchoolStudents,
          }
        });
        modal.onDidDismiss().then(({ data }) => {
          if (data && data.selectedProfessions) {
            // fill default choice of related segments
            for (const prof of data.selectedProfessions) {
              if (!prof.selectedSegments || prof.selectedSegments?.length == 0) {
                const prevSelected = userSegments.value.filter(us => us.professionId == prof.id && us.reaction == 'like');
                const relatedSegments = prevSelected.length > 0 ? prevSelected.map(us => allSegments.value.find(s => s.id == us.segmentId)).filter(us => !!us)
                                                                : store.getters.getRelatedSegmentsByProfessionId(prof.id);
                userSegments.value = [
                  ...userSegments.value.filter(us => us.professionId != prof.id),
                  ...relatedSegments.map(s => ({
                    professionId: prof.id,
                    segmentId: s.id,
                    reason: "",
                    action: "",
                    reaction: 'like',
                    createdAt: new Date(),
                    appState: {},
                  })),
                ];
                prof.selectedSegments = relatedSegments;
              }
            }
            selectedProfessions.value = data.selectedProfessions;
            userProfessions.value = data.userProfessions;
          }
        });
        return modal.present();
      },
      openUniSegmentDeckModal: async (profession) => {
        // "Reset" the choices to default related segments
        const relatedSegments = store.getters.getRelatedSegmentsByProfessionId(profession.id);
        const currSelectedSegments = profession.selectedSegments.slice();
        profession.selectedSegments = [
          ...currSelectedSegments.filter(cs => relatedSegments.find(s => s.id == cs.id)),
          ...relatedSegments.filter(s => !currSelectedSegments.find(cs => cs.id == s.id)),
        ]
        userSegments.value = [
          ...userSegments.value.filter(us => us.professionId != profession.id),
          ...profession.selectedSegments.map(s => ({
            professionId: profession.id,
            segmentId: s.id,
            reason: "",
            action: "",
            reaction: 'like',
            createdAt: new Date(),
            appState: {},
          })),
        ];
        /*
        const modal = await modalController.create({
          component: UniSegmentDeckModal,
          componentProps: {
            professionId: profession.id,
            professionName: profession.name,
            prefilledSegments: profession.selectedSegments,
            oldUserSegments: userSegments.value.filter(us => us.professionId == profession.id),
          }
        });
        modal.onDidDismiss().then(({ data }) => {
          if (data && data.selectedSegments) {
            profession.selectedSegments = data.selectedSegments;
            userSegments.value = [
              ...userSegments.value.filter(us => us.professionId != profession.id),
              ...data.userSegments, // new user segments after segment deck
            ]
          }
        });
        return modal.present();
        */
      },
      onDeleteChosenSegment: (profession, idx, segment) => {
        profession.selectedSegments.splice(idx, 1);
        const relatedUserSegment = userSegments.value.find(us => us.segmentId == segment.id && us.professionId == profession.id);
        if (relatedUserSegment) relatedUserSegment.reaction = '';
      },
      onDeleteChosenProfession: (idx, profession) => {
        selectedProfessions.value.splice(idx, 1);
        const relatedUserProfession = userProfessions.value.find(us => us.professionId == profession.id);
        if (relatedUserProfession) relatedUserProfession.reaction = '';
      },

      // Filter groups & filters
      chevronBack, chevronForward, repeat, search,
    }
  },
});

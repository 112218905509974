
// Vue reactivity
import { ref, defineComponent, computed, watch, reactive, onMounted } from 'vue';

// icons
import { close, arrowBack, checkmarkCircle, alertCircle, ellipsisHorizontal, ellipsisVertical, } from 'ionicons/icons';

// components
import { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter, IonRow, IonCol,
        IonItem, IonLabel, IonIcon, IonButtons, IonButton, IonSpinner, IonList,
        IonCard, IonCardHeader, IonCardSubtitle, IonCardContent, IonPopover,
        IonAccordion, IonAccordionGroup, IonText,
        loadingController, modalController, alertController, } from '@ionic/vue';
import SessionStudentListModal from '@/components/modals/SessionStudentListModal.vue';
import SLPModal from '@/components/secondary/ABSLPModal.vue';
import UniEventsDetails from '@/components/client/UniEventsDetails.vue'
import ServiceModal from '../modals/ServiceModal.vue';

// composables / services
import { useI18n } from 'vue-i18n';
import { utils } from '@/composables/utils';
import { useStore } from '@/store';
import CommonService from '@/services/CommonService';

export default {
  props: [],
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter, IonRow, IonCol,
    IonItem, IonLabel, IonIcon, IonButtons, IonButton, IonSpinner, IonList,
    IonCard, IonCardHeader, IonCardSubtitle, IonCardContent, IonPopover,
    IonAccordion, IonAccordionGroup, IonText, },
  setup(props) {
    // methods or filters
    const store = useStore();
    const { t } = useI18n();
    const { openModal, formatDate, openImageModal, getProxyImgLink, } = utils();

    //const loadingData = computed(() => store.state.loadingUser || store.state.loadingData || store.state.loadingSchoolUserData);
    const loadingData = computed(() => store.state.loadingUser || store.state.loadingData);
    const user = computed(() => store.state.user);
    const userRelatedClient = computed(() => store.getters.userRelatedClient);
    const clientHomeSlideImgUrl = ref(null);
    const clientSessions = computed(() => store.getters.getWorkEventSessionsByClientId(user.value.clientId || "c955c4b06"));
    const clientServices = computed(() => store.getters.getSecSchoolServicesByClientId(user.value.clientId || "c955c4b06"));
    const clientUniSessions = computed(() => store.getters.getUniEventSessionsByClientId(user.value.clientId || "c955c4b06"));
    const clientUniServices = computed(() => store.getters.getUniServicesByClientId(user.value.clientId || "c955c4b06"));

    onMounted(() => {
      const { homeSlideLink } = userRelatedClient.value;
      if (homeSlideLink) {
        CommonService.getSlideImageUrl(homeSlideLink).then(res => {
          clientHomeSlideImgUrl.value = res;
        })
      }
    })
  

    // return variables & methods to be used in template HTML
    return {
      // icons
      close, arrowBack,
      checkmarkCircle, alertCircle, ellipsisHorizontal, ellipsisVertical, 

      // variables
      user, userRelatedClient, clientHomeSlideImgUrl,
      loadingData,
      clientSessions,
      clientUniServices,
      clientServices,
      groupedSessions: () => {
        const obj = {};
        for (const session of clientSessions.value) {
          let { group } = session;
          switch (session.anchorEventId) {
            case 'work-workshop-2':
              group = "Workshop at university campus";
              break;
            case 'work-campus-visit':
              group = "University visit";
              break;
          }
          obj[group] = obj[group] || [];
          obj[group].push(session);
        }
        return obj;
      },

      groupedWorkSessions: () => {
        const obj = {};
        for (const session of clientSessions.value) {
          let group = ""
          const { startTimeStr, anchorEventId, status } = session;
          if (status == "Rejected") continue
          if (new Date(startTimeStr) > new Date(+new Date() - 86400000)){
            group = "future"
          } else {
            group = "past"
          }
          if (anchorEventId == 'work-workshop-2'){
            session.displayGroupName = "Workshop at university campus"
          } else if (anchorEventId == 'work-campus-visit') {
            session.displayGroupName = "University visit"
          } else {
            session.displayGroupName = ""
          }

          obj[group] = obj[group] || [];
          obj[group].push(session);
        }
        return obj;
      },

      groupedUniSessions: () => {
        const obj = {};
        for (const session of clientUniSessions.value) {
          let group = ""
          const { startTimeStr, anchorEventId, status } = session;
          if (status == "Rejected") continue
          if (new Date(startTimeStr) > new Date(+new Date() - 86400000)){
            group = "future"
          } else {
            group = "past"
          }
          if (anchorEventId == 'workshop2'){
            session.displayGroupName = "Educated job seeking"
          } else if (anchorEventId == 'cc31fb5c1') {
            session.displayGroupName = "Guest lecture"
          } else {
            session.displayGroupName = ""
          }

          obj[group] = obj[group] || [];
          obj[group].push(session);
        }
        return obj;
      },

      // methods
      t,
      formatDate,
      closeModal: async () => (await modalController.dismiss({})),

      openImageModal, getProxyImgLink,

      openServiceModal: async (serviceId) => (await openModal(ServiceModal, { serviceId, isClientView: true, hideTeacherResponse: false })),

      openSessionStudentListModal: async (sessionId) => (await openModal(SessionStudentListModal, { sessionId })),

      openSessionUniStudentListModal: async (sessionId) => (await openModal(SessionStudentListModal, { sessionId, isStudentView: false, isShowUniStudents: true })),

      openSLPModal: async () => {
        const ev = store.getters.getLastAttendedSession;
        return await openModal(SLPModal, { ev, serviceId: ev?.serviceId, relatedProgramId: ev?.relatedProgramId, relatedClientId: user.value.clientId }, "", false)
      },

      openUniEventDetails: async (sessionId) => (await openModal(UniEventsDetails, { sessionId })),

      openProposeDateModal: async () => (alert("Propose")),
    }
  },
};

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, normalizeStyle as _normalizeStyle, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4dfcff7c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "ion-padding-start" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "ion-padding-start" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!

  return ($props.session)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_ion_item, {
          lines: "full",
          onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.openServiceModal($props.session.serviceId, $props.session))),
          detail: "",
          button: "",
          style: _normalizeStyle({ 'opacity': $setup.isSkipped() || $props.session.userResponse?.response == 'Unsubscribed' ? 0.5 : 1 })
        }, {
          default: _withCtx(() => [
            false
              ? (_openBlock(), _createBlock(_component_ion_label, {
                  key: 0,
                  class: _normalizeClass([{ 'padding-start': $props.paddingStart == true }, "ion-text-wrap"])
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("b", null, _toDisplayString($props.session.serviceTagline), 1),
                    _createElementVNode("p", _hoisted_2, [
                      _createElementVNode("b", null, [
                        _createTextVNode(_toDisplayString($setup.getSessionDisplayName()) + " | ", 1),
                        ($props.session.anchorEventId == 'lms-scholarship')
                          ? (_openBlock(), _createElementBlock("span", _hoisted_3, "dl: "))
                          : _createCommentVNode("", true),
                        _createTextVNode(_toDisplayString($props.session.date), 1)
                      ])
                    ])
                  ]),
                  _: 1
                }, 8, ["class"]))
              : (_openBlock(), _createBlock(_component_ion_label, {
                  key: 1,
                  class: _normalizeClass([{ 'padding-start': $props.paddingStart == true }, "ion-text-wrap"])
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("b", null, _toDisplayString($setup.getSessionDisplayName()), 1)
                  ]),
                  _: 1
                }, 8, ["class"]))
          ]),
          _: 1
        }, 8, ["style"])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_4, [
        _withDirectives((_openBlock(), _createBlock(_component_ion_item, {
          key: $props.service.id,
          lines: "full",
          disabled: $props.service.status == 'Inactive',
          detail: "",
          button: "",
          onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.openServiceModal($props.service.id))),
          style: _normalizeStyle({ 'opacity': $setup.isSkipped() || $props.teacherResp?.role == 'Not involved' ? 0.5 : 1 })
        }, {
          default: _withCtx(() => [
            false
              ? (_openBlock(), _createBlock(_component_ion_label, {
                  key: 0,
                  class: _normalizeClass([{ 'padding-start': $props.paddingStart == true }, "ion-text-wrap"])
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("b", null, _toDisplayString($props.service.tagline), 1),
                    _createElementVNode("p", _hoisted_5, [
                      _createElementVNode("b", null, _toDisplayString($setup.getServiceDisplayName()), 1)
                    ])
                  ]),
                  _: 1
                }, 8, ["class"]))
              : (_openBlock(), _createBlock(_component_ion_label, {
                  key: 1,
                  class: _normalizeClass([{ 'padding-start': $props.paddingStart == true }, "ion-text-wrap"])
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("b", null, _toDisplayString($setup.getServiceDisplayName()), 1)
                  ]),
                  _: 1
                }, 8, ["class"]))
          ]),
          _: 1
        }, 8, ["disabled", "style"])), [
          [_vShow, $setup.isShowService()]
        ])
      ]))
}

// Vue reactivity
import { ref, defineComponent, computed, watch, reactive } from 'vue';

// icons
import { add, close, checkmark, arrowUp,  arrowForward, arrowBack, trashOutline, } from 'ionicons/icons';

// components
import { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter, IonRow, IonCol, IonAccordion, IonAccordionGroup,
        IonItem, IonLabel, IonIcon, IonButtons, IonButton,
        IonSearchbar, IonSegment, IonSegmentButton, IonList, IonSelect, IonSelectOption,
        IonCard, IonCardHeader, IonCardSubtitle, IonCardContent, IonChip, IonText, IonCardTitle, IonGrid, IonCheckbox,
        IonRadioGroup, IonRadio, IonInfiniteScroll, IonInfiniteScrollContent, IonReorder, IonReorderGroup,
        loadingController, modalController, } from '@ionic/vue';

// composables / services
import { useI18n } from 'vue-i18n';
import { utils } from '@/composables/utils';
import { useStore } from '@/store';

// types
import { User, Session, Discipline } from '@/types';

export default defineComponent({
  name: 'WorkEventsModal',
  props: ["chosenDisciplines", "showTeacherActions", "anchorEvId"],
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter, IonRow, IonCol, IonAccordion, IonAccordionGroup,
                IonItem, IonLabel, IonIcon, IonButtons, IonButton,
                IonSearchbar, IonSegment, IonSegmentButton, IonList, IonSelect, IonSelectOption,
                IonCard, IonCardHeader, IonCardSubtitle, IonCardContent, IonChip, IonText, IonCardTitle, IonGrid, IonCheckbox,
                IonRadioGroup, IonRadio, IonInfiniteScroll, IonInfiniteScrollContent, IonReorder, IonReorderGroup, },
  setup(props) {
    // methods or filters
    const store = useStore();
    const { openModal, presentPrompt, getF6YearDSE, } = utils();
    const { t } = useI18n();

    const user = computed(() => store.state.user);
    const numOfAppliedWorkEvents = computed(() => store.getters.numOfAppliedWorkEvents);
    const workEvents = computed<Session[]>(() => store.getters.getSessionsByAnchorEventId(props.anchorEvId));
    const allEvDisciplineGroups = [...new Set(workEvents.value.map(ev => ev.targetDisciplineGroups).flat())].sort();
    const searchKeyword = ref("");
    const selectedFilter = ref("all");

    const closeModal = async () => (await modalController.dismiss({}));

    // return variables & methods to be used in template HTML
    return {
      // icons
      add, close, checkmark, arrowUp, arrowForward, arrowBack,
      trashOutline,

      // variables
      user, numOfAppliedWorkEvents,
      workEvents, allEvDisciplineGroups,
      searchKeyword,
      selectedFilter,

      // methods
      t,
      closeModal,
      isShowEvent: (ev: Session) => {
        if (ev.name.startsWith("University visit @")) return false;
        if (ev.name.toLowerCase().includes("band a")) {
          return false; // Hide Band A workshops (only applicable via dedicated registration link)
        }
        if (!user.value.teacher) {
          /*
          // TODO: bug fixes
          if (user.value.yearDSE == getF6YearDSE().toString()) {
            // Special handling for F.6 students (e.g. only show applied band A workshops)
            // Prevent side-track them for other events
            return ev.userResponse?.confirmed == 'Yes';
          }
          */
          if (ev.targetDSEYears.length > 0 && !ev.targetDSEYears.includes(user.value.yearDSE)) {
            return false;
          }
        }
        if (searchKeyword.value) {
          const searchInWords = `${ev.name} ${ev.description}`.replace(/[^a-z0-9]/gi, "").toLowerCase();
          const cleanedKeyword = searchKeyword.value.replace(/[^a-z0-9]/gi, "").toLowerCase();
          return searchInWords.includes(cleanedKeyword);
        }
        if (selectedFilter.value == 'applied') {
          return ev.userResponse?.confirmed == "Yes";
        }
        if (selectedFilter.value == 'offline') {
          return ev.mode != "Online";
        }
        return new Date().getFullYear() <= new Date(ev.startTimeStr).getFullYear() && 
                (selectedFilter.value == 'all' || ev.type == selectedFilter.value ||
                  ev.targetDisciplineGroups.includes(selectedFilter.value.toString().split(":")[0]));
      },
    }
  },
});

import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ABEventsDetails = _resolveComponent("ABEventsDetails")!
  const _component_service_details = _resolveComponent("service-details")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.isAB4User)
      ? (_openBlock(), _createBlock(_component_ion_header, { key: 0 }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_toolbar, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_buttons, { slot: "start" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_button, {
                      slot: "icon-only",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeModal()))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_icon, { icon: _ctx.arrowBack }, null, 8, ["icon"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_title, { style: {"padding-left":"0"} }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                      default: _withCtx(() => [
                        _createElementVNode("p", null, _toDisplayString(_ctx.lesson.lessonShortName || _ctx.lesson.lessonDisplayName), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_ion_content, null, {
      default: _withCtx(() => [
        (_ctx.isAB4User)
          ? (_openBlock(), _createBlock(_component_ABEventsDetails, {
              key: 0,
              targetAnchorEvId: _ctx.targetAnchorEvId,
              showHeader: true
            }, null, 8, ["targetAnchorEvId"]))
          : (_openBlock(), _createBlock(_component_service_details, {
              key: 1,
              hideHeader: true,
              serviceId: "s9215dbaf"
            }))
      ]),
      _: 1
    })
  ], 64))
}
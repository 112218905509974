
// Vue reactivity
import { ref, defineComponent, computed, watch, reactive, onMounted, nextTick, } from 'vue';

// icons
import { add, close, checkmark, arrowUp,  arrowForward, arrowBack, trashOutline, sendOutline, helpCircleOutline,
        createOutline, openOutline, chevronForwardOutline, informationCircleOutline, personAddOutline, pencil,
        downloadOutline, star, starOutline, arrowDown, addCircleOutline, settingsOutline,
        filter, closeCircle, logoWhatsapp, } from 'ionicons/icons';

// components
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonFooter, IonRow, IonCol, IonAccordion, IonAccordionGroup,
        IonItem, IonLabel, IonIcon, IonButtons, IonButton, IonBadge,
        IonSearchbar, IonSegment, IonSegmentButton, IonList, IonSelect, IonSelectOption,
        IonCard, IonCardHeader, IonCardSubtitle, IonCardContent, IonChip, IonText, IonCardTitle, IonGrid, IonCheckbox,
        IonInfiniteScroll, IonInfiniteScrollContent, IonReorder, IonReorderGroup, IonListHeader,
        IonFab, IonFabButton, IonRange, IonInput, IonModal, IonPopover, IonSpinner, IonTextarea,
        loadingController, modalController, alertController, } from '@ionic/vue';
import UserDetailsModal from '@/components/modals/UserDetailsModal.vue';
import AchieveJUPASResultPageModal from '@/components/achievejupas/AchieveJUPASResultPageModal.vue';
import SchoolSelectModal from '@/components/secondary/SchoolSelectModal.vue';
import ABProgramSelectModal from '@/components/achievejupas/ABProgramSelectModal.vue';
import ABDisciplineSelectModal from '@/components/pss/ABDisciplineSelectModal.vue';

// composables / services
import { useI18n } from 'vue-i18n';
import { utils } from '@/composables/utils';
import { useStore } from '@/store';
import { useRouter } from 'vue-router';

// services

// types
import { Program, Discipline, DisciplineGroup, School, Session, Tag, User } from '@/types';
import InternalService from '@/services/InternalService';

interface JUPASChoiceCondition {
  orders: number[];
  targetEntityType: "Program" | "Discipline" | "Discipline Group";
  targetPrograms: Program[];
  targetDisciplines: Discipline[];
  targetDisciplineGroups: DisciplineGroup[];
  isNecessary: boolean;
}

export default defineComponent({
  name: 'FDMTStaffActionCardList',
  props: [],
  components: { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonFooter, IonRow, IonCol, IonAccordion, IonAccordionGroup,
                IonItem, IonLabel, IonIcon, IonButtons, IonButton, IonBadge,
                IonSearchbar, IonSegment, IonSegmentButton, IonList, IonSelect, IonSelectOption,
                IonCard, IonCardHeader, IonCardSubtitle, IonCardContent, IonChip, IonText, IonCardTitle, IonGrid, IonCheckbox,
                IonInfiniteScroll, IonInfiniteScrollContent, IonReorder, IonReorderGroup, IonListHeader,
                IonFab, IonFabButton, IonRange, IonInput, IonModal, IonPopover, IonSpinner, IonTextarea, },
  setup(props) {
    // methods or filters
    const store = useStore();
    const { openModal, presentPrompt, presentToast, infiniteScrollLoadData, formatStudentNumber,
            getQRCodeUrl, getF6YearDSE, getF5YearDSE, uniqueId, getBandLabel, getSecStudentForm, } = utils();
    const { t } = useI18n();
    const router = useRouter();

    // State variables
    const user = computed(() => store.state.user);
    const allStudents = computed<User[]>(() => store.state.allStudents); // All students
    const allTeachers = computed<User[]>(() => store.state.allTeachers); // All teachers
    const fetchedAllStudents = computed(() => store.state.fetchedAllStudents);
    const fetchedAllTeachers = computed(() => store.state.fetchedAllTeachers);
    const schools = computed<School[]>(() => store.state.schools);
    const schoolsWithStudents = computed(() => {
      return [...new Set(allStudents.value.map(s => s.schoolId))].map(id => schools.value.find(s => s.id == id)).filter(s => s);
    });
    const electives = computed(() => store.state.allElectives);
    const programs = computed(() => store.state.allPrograms);
    const disciplineGroups = computed(() => store.state.allDisciplineGroups);

    // Filter by sessions
    const filterSessions = {
      'e128c552': 'IAWork',
      'e39213ae': 'GBusWork',
      'e7269dfd': 'NursingWork',
      'edc935d8': 'BBAWork',
    };

    // Filters or search
    const searchKeyword = ref("");
    const selectedUserRole = ref('secondary-school-student');
    const filters = reactive({
      status: "all",
      schoolIds: [] as any[],
      bands: {},
      banding: "all",
      schoolDistricts: {},

      // Students
      yearDSE: "all",
      studentClass: "all",
      electives: {},
      jupasChoiceConditions: [] as JUPASChoiceCondition[],
      appliedSessionId: "all",

      // Teachers
      schoolRoles: {},
    })
    const popoverState = reactive({
      school: false,
      bands: false,
      schoolDistricts: false,

      // students
      electives: false,

      // teachers
      schoolRoles: false,
    })
    const popoverEvent = ref();
    const setPopoverOpen = (popoverKey: any, state: boolean, ev: any) => {
      popoverEvent.value = ev; 
      popoverState[popoverKey] = state;
    };
    const selectedFilterCheckboxVals = (key: string) => {
      return Object.keys(filters[key]).filter(k => filters[key][k]);
    };

    // For AchieveJUPAS choice filters
    const addChoiceFilter = () => {
      filters.jupasChoiceConditions.push({
        orders: [], // 1 = A1, 2 = A2, ... 
        targetEntityType: "Program",
        targetPrograms: [],
        targetDisciplines: [],
        targetDisciplineGroups: [],
        isNecessary: false,
      });
    }
    const getValidJUPASChoiceConditions = () => {
      return filters.jupasChoiceConditions.filter(c => {
        if (c.targetEntityType === 'Program') return c.targetPrograms.length > 0;
        if (c.targetEntityType === 'Discipline') return c.targetDisciplines.length > 0;
        if (c.targetEntityType === 'Discipline Group') return c.targetDisciplineGroups.length > 0;
        return false;
      });
    }

    // Modal
    const modalState = reactive({
      schoolSelect: false,
      sendMessage: false,
      jupasChoiceConditions: false,
    });

    // For message sending
    const msgOptions = reactive({
      content: "",
    })
    const msgVariables = {
      '{{name}}': "Full name",
      '@852{{phone}}': "Tag in group",
    }
    const selectedStudentIds = ref<{ [key: string]: boolean }>({});
    const selectedTeacherIds = ref<{ [key: string]: boolean }>({});
    const selectedStudents = computed(() => {
      return Object.keys(selectedStudentIds.value).filter(id => (selectedStudentIds.value[id])).map(id => allStudents.value.find(s => s.id == id));
    })
    const selectedTeachers = computed(() => {
      return Object.keys(selectedTeacherIds.value).filter(id => (selectedTeacherIds.value[id])).map(id => allTeachers.value.find(t => t.id == id));
    })

    // Infinite Scroll
    const numOfVisibleItems = ref(20);
    const loadData = (ev: any) => {
      infiniteScrollLoadData(ev, numOfVisibleItems, allStudents.value, 50);
    }

    // Get count of programs with 'like' reaction
    const getStudentProgramCount = (student: User) => {
      return (student.userPrograms || []).filter(up => up.reaction === 'like').length;
    };

    // Open user details modal
    const openUserDetails = async (student: User) => {
      return await openModal(UserDetailsModal, { user: student, isStudentView: false });
    };

    // Get unique DSE years
    const dseYears = computed(() => {
      const uniqueYrs = [...new Set(allStudents.value.map(s => Number(s.yearDSE)).filter(year => year))];
      return uniqueYrs.sort((a, b) => b - a); // Sort descending
    });

    // Filter teachers based on all criteria
    const filteredTeachers = computed(() => {
      let teachers = allTeachers.value.slice();

      // School filter
      if (filters.schoolIds.length > 0) {
        teachers = teachers.filter(t => filters.schoolIds.includes(t.schoolId));
      }
      // District filter
      if (selectedFilterCheckboxVals('schoolDistricts').length > 0) {
        teachers = teachers.filter(t => {
          const school = schools.value.find(school => school.id === t.schoolId);
          return school && selectedFilterCheckboxVals('schoolDistricts').includes(school.district);
        });
      }
      // Band filter
      if (selectedFilterCheckboxVals('bands').length > 0) {
        teachers = teachers.filter(t => {
          const school = schools.value.find(school => school.id === t.schoolId);
          return school && selectedFilterCheckboxVals('bands').includes(school.band);
        });
      }
      // Band filter (single select)
      if (filters.banding !== 'all') {
        teachers = teachers.filter(t => {
          const school = schools.value.find(school => school.id === t.schoolId);
          return school && school.band === filters.banding;
        });
      }
      // School Roles filter
      if (selectedFilterCheckboxVals('schoolRoles').length > 0) {
        teachers = teachers.filter(t => {
          const roles = t.teacher?.schoolRoles?.split(' , ').map(r => r.trim().toLowerCase());
          return roles && selectedFilterCheckboxVals('schoolRoles').some(r => roles.includes(r));
        });
      }

      // Only show active teachers
      teachers = teachers.filter(t => t.teacher?.employmentStatus == 'Active');

      return teachers;
    })


    // Filter students based on all criteria
    const filteredStudents = computed(() => {
      let students = allStudents.value.slice();

      // School filter
      if (filters.schoolIds.length > 0) {
        students = students.filter(s => filters.schoolIds.includes(s.schoolId));
      }

      // Band filter
      if (selectedFilterCheckboxVals('bands').length > 0) {
        students = students.filter(s => {
          const school = schools.value.find(school => school.id === s.schoolId);
          return school && selectedFilterCheckboxVals('bands').includes(school.band);
        });
      }

      // Band filter (single select)
      if (filters.banding !== 'all') {
        students = students.filter(s => {
          const school = schools.value.find(school => school.id === s.schoolId);
          return school && school.band === filters.banding;
        });
      }

      // Elective filter
      if (selectedFilterCheckboxVals('electives').length > 0) {
        students = students.filter(s => selectedFilterCheckboxVals('electives').some(electiveName => s.studyingElectives?.includes(electiveName)));
      }

      // Year DSE filter
      if (filters.yearDSE !== 'all') {
        students = students.filter(s => s.yearDSE == parseInt(filters.yearDSE));
      }

      // Filtered by JUPAS choices
      if (getValidJUPASChoiceConditions().length > 0) {
        students = students.filter(s => {
          let allFulfilled = false;
          for (const condition of getValidJUPASChoiceConditions()) {
            let fulfilled = false;
            let checkChoices = s.userPrograms || [];
            if (condition.orders.length > 0) {
              checkChoices = checkChoices.filter(c => (c.order ? condition.orders.includes(c.order) : false));
            }
            switch (condition.targetEntityType) {
              case 'Program':
                checkChoices = checkChoices.filter(c => condition.targetPrograms.some(p => p.id == c.programId));
                break;
              case 'Discipline':
                checkChoices = checkChoices.filter(c => {
                  const p = programs.value.find(p => p.id == c.programId);
                  return p && condition.targetDisciplines.some(d => p.disciplines.map(pd => pd.id).includes(d.id));
                });
                break;
              case 'Discipline Group':
                checkChoices = checkChoices.filter(c => {
                  const p = programs.value.find(p => p.id == c.programId);
                  return p && condition.targetDisciplineGroups.some(dg => p.disciplines.map(pd => pd.disciplineGroupId).includes(dg.id));
                });
                break;
            }
            if (checkChoices.length > 0) {
              fulfilled = true;
              allFulfilled = true; // OR condition (any 1 condition matches)
            }
            if (condition.isNecessary && !fulfilled) { // AND condition
              allFulfilled = false;
              break;
            }
          }
          return allFulfilled;
        });
      }

      // Applied Workshop Session filter
      if (filters.appliedSessionId !== 'all') {
        if (filters.appliedSessionId == 'joined-more-than-1') { // Join more than 1 Work
          const sessionIds = Object.keys(filterSessions); // workshop session IDs
          students = students.filter(s => {
            const filteredResponses = (s.sessionResponses || []).filter(sr => (['Yes', 'Confirmed', 'Attended'].includes(sr.response) && sessionIds.includes(sr.sessionId)));
            if (filteredResponses.length > 1) {
              s.filteredSessionResponses = filteredResponses;
              return true;
            }
            return false;
          });
        } else {
          students = students.filter(s => {
            return s.sessionResponses?.find(sr => (['Yes', 'Confirmed', 'Attended'].includes(sr.response) && sr.sessionId === filters.appliedSessionId));
          });
        }
      }
      
      // Text search
      if (searchKeyword.value) {
        const cleanedKeyword = searchKeyword.value.replace(/[^a-z0-9]/gi, "").toLowerCase();
        students = students.filter((s: User) => {
          const searchInWords = `${s.fullName} ${s.chineseName} ${s.preferredName} ${s.phone} ${s.email} ${s.class}${s.studentNumber}`
            .replace(/[^a-z0-9]/gi, "").toLowerCase();
          return searchInWords.includes(cleanedKeyword);
        });
      }

      // Class filter
      if (filters.studentClass !== 'all') {
        students = students.filter(s => s.class === filters.studentClass);
      }

      // AchieveJUPAS completion status filter
      // TODO: missing target scores etc.
      if (filters.status !== 'all') {
        students = students.filter(s => {
          const programCount = getStudentProgramCount(s);
          return filters.status === 'complete' ? programCount >= 20 : programCount < 6;
        });
      }

      return students;

      // Sort by DSE year (desc), then class, then student number
      /*return students.sort((a, b) => {
        if (a.yearDSE !== b.yearDSE) return (b.yearDSE || 0) - (a.yearDSE || 0);
        const classA = a.class?.toUpperCase() || '';
        const classB = b.class?.toUpperCase() || '';
        if (classA !== classB) return classA.localeCompare(classB);
        return (a.studentNumber || '').toString().localeCompare(b.studentNumber || '');
      });*/
    });
    
    const visibleSchoolIds = computed(() => {
      const targetUsers = selectedUserRole.value == 'secondary-school-student' ? filteredStudents.value : filteredTeachers.value;
      return [...new Set(targetUsers.map(s => s.schoolId))].filter(s => s);
    });
    const visibleSchoolDistricts = computed(() => {
      const targetUsers = selectedUserRole.value == 'secondary-school-student' ? allStudents.value : allTeachers.value;
      return [...new Set(targetUsers.map(s => s.schoolId).map(schoolId => schools.value.find(sch => sch.id == schoolId)?.district))].filter(d => d).sort();
    });

    // Recipient
    const recipientStudents = computed<any>(() => (selectedStudents.value.length > 0 ? selectedStudents.value : filteredStudents.value));
    const recipientTeachers = computed<any>(() => (selectedTeachers.value.length > 0 ? selectedTeachers.value : filteredTeachers.value));

    onMounted(() => {
      if (!fetchedAllStudents.value || !fetchedAllTeachers.value) {
        store.dispatch('fetchAllStudentsAndTeachers');
      }
    })

    watch(selectedUserRole, () => {
      numOfVisibleItems.value = 20; // reset
    })

    watch(filteredTeachers, () => {
      selectedTeacherIds.value = {}; // unselect after filters changed
    })
    watch(filteredStudents, () => {
      selectedStudentIds.value = {}; // unselect after filters changed
    })

    return {
      // icons
      add, close, checkmark, arrowUp, arrowForward, arrowBack,
      trashOutline, sendOutline, helpCircleOutline, createOutline, openOutline,
      chevronForwardOutline, informationCircleOutline, personAddOutline, pencil,
      downloadOutline, star, starOutline, arrowDown, addCircleOutline, settingsOutline,
      filter, closeCircle, logoWhatsapp,

      // variables
      user, electives, disciplineGroups,
      searchKeyword,
      dseYears,
      visibleSchoolIds,
      visibleSchoolDistricts,
      
      // Mega student / teacher list
      selectedUserRole,
      fetchedAllStudents, filteredStudents, // students
      fetchedAllTeachers, filteredTeachers, // teachers

      // Teachers
      visibleSchoolRoles: computed(() => {
        const allRoles: string[] = [];
        for (const s of allTeachers.value) {
          const roles: any = s.teacher?.schoolRoles?.split(" , ").filter(r => r).map(r => r.toLowerCase());
          if (roles) allRoles.push(...roles);
        }
        return [...new Set(allRoles)].filter(s => s).sort();
      }),
      
      // Send Messages
      recipientStudents, recipientTeachers,
      recipientCountDescription: computed(() => {
        if (selectedUserRole.value == 'secondary-school-student') {
          const targetStudents = selectedStudents.value.length > 0 ? selectedStudents.value : filteredStudents.value;
          const schoolIds = [...new Set(targetStudents.map(s => s?.schoolId))].filter(s => s);
          return `${targetStudents.length} student${targetStudents.length > 1 ? 's' : ''} from ${schoolIds.length} school${schoolIds.length > 1 ? 's' : ''}`;
        } else {
          const targetTeachers = selectedTeachers.value.length > 0 ? selectedTeachers.value : filteredTeachers.value;
          const schoolIds = [...new Set(targetTeachers.map(t => t?.schoolId))].filter(s => s);
          return `${targetTeachers.length} teacher${targetTeachers.length > 1 ? 's' : ''} from ${schoolIds.length} school${schoolIds.length > 1 ? 's' : ''}`;
        }
      }),
      selectedStudentIds, selectedTeacherIds,
      msgOptions, msgVariables,
      sendMessage: () => {
        const target = selectedUserRole.value == 'secondary-school-student' ? 'student' : 'teacher';
        presentPrompt(`Confirm sending the message to the ${target}s?`, async () => {
          const users = (target == 'student' ? recipientStudents.value : recipientTeachers.value).map(s => {
            let msg = msgOptions.content; // find variables & prefill msgs for each student
            const findReplacePairs = {
              "{{name}}": s.fullName,
              "{{phone}}": s.phone,
            }
            for (const find in findReplacePairs) {
              const findRegExp = new RegExp(find, "gi");
              msg = msg.replace(findRegExp, findReplacePairs[find]);
            }
            return { waGroupId: s.waGroupId, phone: s.phone, msg: msg }
          });
          InternalService.sendWhatsAppMsgToUsers(users, msgOptions.content);
          presentToast('The TBC messages will be generated in 17/F WhatsApp Terminal -> Outbox shortly.');
        });
      },
      insertVariableAtCursor: (key) => { // Function to insert variable at cursor position
        const textarea: any = document.querySelector('#messageTextarea textarea');
        if (textarea) {
          const startPos = textarea.selectionStart;
          const endPos = textarea.selectionEnd;
          const currentContent = msgOptions.content || '';

          // Insert the key at the cursor position
          msgOptions.content =
            currentContent.substring(0, startPos) +
            ` ${key} ` +
            currentContent.substring(endPos);

          // Move the cursor after the inserted variable
          const newCursorPos = startPos + key.length + 1; // +1 for the space
          nextTick(() => {
            textarea.focus();
            textarea.setSelectionRange(newCursorPos, newCursorPos);
          });
        }
      },

      // Infinite scroll
      loadData, numOfVisibleItems,

      // methods
      t, getQRCodeUrl,
      getStudentProgramCount,
      openUserDetails,
      getSecStudentForm,

      // Applied Sessions
      getStudentSessionResp: (s, targetKey = 'response') => {
        return (s.sessionResponses?.find(r => r.sessionId == filters.appliedSessionId) || {})[targetKey];
      },

      // JUPAS Choice Conditions (filtering)
      getBandLabel, addChoiceFilter,
      openJUPASChoiceConditionModal: () => {
        if (filters.jupasChoiceConditions.length == 0) addChoiceFilter();
        modalState.jupasChoiceConditions = true;
      },
      closeJUPASChoiceConditionModal: () => {
        filters.jupasChoiceConditions = getValidJUPASChoiceConditions();
        modalState.jupasChoiceConditions = false;
      },
      openEntitySelectModal: async (targetCondition: JUPASChoiceCondition) => {
        // Check open Program / Discipline search select
        if (targetCondition.targetEntityType == 'Program') {
          const modal = await modalController.create({
            component: ABProgramSelectModal,
            componentProps: { prefilledPrograms: targetCondition.targetPrograms.slice() },
          });
          modal.onDidDismiss().then(({ data }) => {
            if (data && data.chosen) {
              targetCondition.targetPrograms = data.chosen;
            }
          });
          return modal.present();
        }
        if (targetCondition.targetEntityType == 'Discipline') {
          const modal = await modalController.create({
            component: ABDisciplineSelectModal,
            componentProps: { isListSelect: true, prefilledDisciplines: targetCondition.targetDisciplines.slice() },
          });
          modal.onDidDismiss().then(({ data }) => {
            if (data && data.chosen) {
              targetCondition.targetDisciplines = data.chosen;
            }
          });
          return modal.present();
        }
      },
      getTargetEntityNames: (targetCondition: JUPASChoiceCondition) => {
        switch (targetCondition.targetEntityType) {
          case 'Program':
            return targetCondition.targetPrograms.map(p => p.displayName).join('\n');
          case 'Discipline':
            return targetCondition.targetDisciplines.map(d => d.name).join('\n');
          case 'Discipline Group':
            return targetCondition.targetDisciplineGroups.map(dg => dg.name).join('\n');
        }
        return '';
      },

      // Modals
      modalState,

      // popover
      popoverState, popoverEvent, setPopoverOpen,

      // Filters
      filterSessions, // Mainly workshop session (IAWork, BBAWork, ...)
      filters, // selected filters
      updateFilters: (key, val) => {
        filters[key] = val;
      },
      selectedFilterCheckboxVals,
      openSchoolSelectModal: async () => {
        if (!modalState.schoolSelect) {
          modalState.schoolSelect = true; // prevent duplicate open
          const modal = await modalController.create({
            component: SchoolSelectModal,
            componentProps: {
              schools: schoolsWithStudents.value, // only show schools with registered students
              isMultiSelect: true,
              prefilledSchoolIds: filters.schoolIds,
            }
          });
          modal.onDidDismiss().then(({ data }) => {
            if (data && data.selectedSchoolIds) {
              console.log(data.selectedSchoolIds);
              filters.schoolIds = data.selectedSchoolIds;
            }
            modalState.schoolSelect = false;
          });
          return modal.present();
        }
      }
    };
  },
});

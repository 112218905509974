
// Vue reactivity
import { ref, defineComponent, computed, watch, reactive } from 'vue';

// icons
import { add, close, arrowBack, checkmarkCircle, alertCircle, ellipsisHorizontal, ellipsisVertical, pencil, } from 'ionicons/icons';

// components
import { IonItem, IonLabel, IonIcon, IonButtons, IonButton, IonAccordion, IonAccordionGroup, } from '@ionic/vue';
import ServiceModal from '@/components/modals/ServiceModal.vue';

// composables / services
import { useI18n } from 'vue-i18n';
import { utils } from '@/composables/utils';
import { useStore } from '@/store';

// types
import { User } from '@/types';

export default {
  props: ["service", "session", "textColor", "teacherResp", "paddingStart", "targetClass", "hideIfScheduled", "showOnlyScheduled"],
  components: { IonItem, IonLabel, IonIcon, IonButtons, IonButton, IonAccordion, IonAccordionGroup, },
  setup(props) {
    const { t } = useI18n();
    const { openModal, } = utils();
    const store = useStore();
    const user = computed(() => store.state.user);
    const schoolTeachers = computed<User[]>(() => store.state.schoolTeachers);
    const clients = computed(() => store.state.allClients);
    const userSchoolRoles = computed(() => (user.value.teacher?.schoolRoles || "").split(" , ").filter(r => r));
    const firstSchoolRole = computed(() => userSchoolRoles.value[0] || "");
    const getClientSubtagline = (clientId) => {
      const client = clients.value?.find(c => c.id === clientId);
      if (!client) return '';
      const schoolRole = store.state.schoolRoles.find(r => r.title === firstSchoolRole.value);
      const res = client.subtagline?.replace(/{{related_subject}}/g, schoolRole?.relatedSubject ? `${schoolRole.alias}-related` : '');
      return res ? `, ${res}` : '';
    }

    // Get related teacher response by class / service
    const getRelatedTeacherResp = (teacherResponses, targetKey = '') => {
      const { session, service, targetClass, } =  props;
      const serviceId = session ? session.serviceId : service.id;
      const { currIntakeYear: intakeYear } = store.getters.getServiceById(serviceId);
      const resp = teacherResponses?.find(r => {
        if (session && r.sessionId != session.id) return false; // track by session
        if (targetClass && r.targetClass != targetClass) return false; // track by class
        if (r.serviceId != serviceId || (intakeYear && r.intakeYear != intakeYear)) {
          return false;
        }
        return true;
      });
      return targetKey ? (resp || {})[targetKey] : resp;
    }

    const OPTION_IDS = {
      playedVideo: 'o-7f6e61d0',
      pubInOtherMeans: 'o-02bd98ac',
      bookTime: 'o-6858aa15',
      findingTime: 'o-08a9ae49',
      skip: 'o-e8602212',
    };

    const getServiceTeacherResponseStatus = (returnOptionId = false) => {
      for (const t of schoolTeachers.value) {
        const teacherResp = getRelatedTeacherResp(t.teacherResponses);
        switch (teacherResp?.optionId) {
          case OPTION_IDS.skip:
            if (t.id == user.value.id) return returnOptionId ? teacherResp.optionId : 'Skipped';
            continue;
          case OPTION_IDS.bookTime:
            return returnOptionId ? teacherResp.optionId : `Scheduled by ${t.fullName} on ${teacherResp.preferredSessionDates}`;
        }
      }
    }

    // return variables & methods to be used in template HTML
    return {
      // icons
      add, close, arrowBack,
      checkmarkCircle, alertCircle, ellipsisHorizontal, ellipsisVertical, pencil,

      // variables

      // methods
      t,
      openServiceModal: async (serviceId: any, event: any = null) => {
        if (serviceId) {
          return await openModal(ServiceModal, { serviceId, event })
        }
      },
      getSessionDisplayName() {
        const { session } = props;
        const { clientId, serviceUniversity, id, serviceNature, serviceName, serviceId, } = session;
        if (serviceId == 'ucircle') return session.name; // UCircle

        let title = "";
        title += `· ${serviceName}`; // e.g. BBAWork
        /*
        if (serviceName.toLowerCase().includes('preview')) {
          title += ', 0.5-hr @ Zoom';
        } else {
          //if (serviceNature == 'Workshop') title += ' video, 4-hr';
          //else if (serviceNature == 'Visit') title += ' video, 2-hr';
          if (serviceUniversity) title += ` @ ${serviceUniversity}`;
        }
        title += ` ${session.date}`;
        title += `${getClientSubtagline(clientId)}`*/
        /*if (serviceNature) title += ` ${serviceNature.toLowerCase()}`; // e.g. visit
        if (id == 'e895b20a') title += ' | 1.5-hr';
        else if (serviceNature == 'Workshop') title += ' | 4-hr';
        else if (serviceNature == 'Visit') title += ' | 2-hr';
        if (serviceUniversity) title += ` | ${serviceUniversity}`;*/
        return title;
      },
      getServiceDisplayName() {
        const { university, clientId, id, nature, name, isAllowProposeDates, } = props.service;
        let title = "";
        title += `· ${name}`; // e.g. BBAWork
        //if (['Workshop', 'Visit'].includes(nature)) title += ` ${nature.toLowerCase()}`; // e.g. visit
        //if (['Workshop', 'Visit'].includes(nature)) title += ` video`; // e.g. visit
        //if (nature == 'Workshop') title += ', 4-hr';
        //else if (nature == 'Visit') title += ', 2-hr';
        /*if (university) {
          title += ` @ ${university}`;
          //if (isAllowProposeDates) title += ` | school-suggested time`; // allow book time
        }
        title += `${getClientSubtagline(clientId)}`*/
        return title;
      },
      OPTION_IDS,

      // Class-based status
      getSessionTeacherResponseStatus: () => {
        for (const t of schoolTeachers.value) {
          const teacherResp = getRelatedTeacherResp(t.teacherResponses);
          switch (teacherResp?.optionId) {
            case OPTION_IDS.skip:
              if (t.id == user.value.id) return 'Skipped';
              continue;
            case OPTION_IDS.playedVideo:
            case OPTION_IDS.pubInOtherMeans:
              return `Publicized by ${t.fullName} (Nomination still welcome)`;
          }
        }
      },
      getServiceTeacherResponseStatus,


      isSkipped: () => (props.teacherResp?.optionId == OPTION_IDS.skip),

      isShowService: () => {
        const { hideIfScheduled, showOnlyScheduled } = props;
        if (hideIfScheduled) return getServiceTeacherResponseStatus(true) != OPTION_IDS.bookTime;
        if (showOnlyScheduled) return getServiceTeacherResponseStatus(true) == OPTION_IDS.bookTime;
        return true;
      }
    }
  },
};
